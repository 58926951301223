import gsap from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";

export function initSocialEmbedAnimation() {
  const socialEmbeds = document.querySelectorAll(".social-embed");
  if (!socialEmbeds) return;

  gsap.utils.toArray(socialEmbeds).forEach((element) => {
    gsap.registerPlugin(ScrollTrigger);

    const embedPlatform = element.querySelector(".social-embed__platform");
    const embedText = element.querySelector(".social-embed__text");

    if (!embedPlatform || !embedText) return;

    const elementTimeline = gsap.timeline({
      defaults: { duration: 0.5, stagger: 0.15 },
      scrollTrigger: {
        trigger: element,
        start: "top 80%",
        end: "bottom center",
      },
    });

    elementTimeline.fromTo(
      element,
      {
        opacity: 0,
      },
      {
        opacity: 1,
      }
    );

    elementTimeline.fromTo(
      embedPlatform,
      {
        opacity: 0,
        y: 200,
      },
      {
        opacity: 1,
        y: 0,
      }
    );

    elementTimeline.fromTo(
      embedText,
      {
        opacity: 0,
        x: 200,
      },
      {
        opacity: 1,
        x: 0,
      }
    );
  });
}
