import { initInputFilled } from "./inputFilled";
import { initSubmitButton } from "./submitButton";
// import { initSelect } from './select';
// import { initOrderCollapse } from './orderCollapse';
import { $$, $ } from "../utils";

export async function initForm() {
  initInputFilled();
  initSubmitButton();
  // initSelect();
  // initOrderCollapse();

  $$("form").forEach((form) => {
    form.addEventListener("submit", () => {
      $('[type="submit"]', form).classList.add("loading");
    });
    const inputs = $$("input[required], textarea[required]");

    inputs.forEach((input) => {
      const formGroup = input.closest(".form-group");
      if (formGroup) {
        formGroup.classList.remove("error");
      }
      //check if already in error state
      if (input.classList.contains("error")) {
        const formGroup = input.closest(".form-group");
        if (formGroup) {
          formGroup.classList.add("error");
        }
      }
      input.addEventListener("invalid", (event) => {
        const input = event.currentTarget;
        const formGroup = input.closest(".form-group");
        if (formGroup) {
          formGroup.classList.add("error");
          $('[type="submit"]', form).classList.remove("loading");
        }
      });
      input.addEventListener("change", (event) => {
        const input = event.currentTarget;
        const valid = input.checkValidity();
        const formGroup = input.closest(".form-group");
        let helperBlock;
        if(formGroup) helperBlock = formGroup.querySelectorAll(".error.help-block");
        if (valid && formGroup) {
          formGroup.classList.remove("error");
          if (helperBlock.length > 0) {
            helperBlock[0].remove();
          }
        }
      });
    });
  });
}
