/* eslint-disable valid-jsdoc */
import L from "leaflet";
import { GestureHandling } from "leaflet-gesture-handling";
import "leaflet.tilelayer.colorfilter";
import ScrollTo from "./scrollToNextElement";
import "leaflet.markercluster/dist/leaflet.markercluster-src";

export async function initLeaflet() {
  /*
   * L.TileLayer.Grayscale is a regular tilelayer with grayscale makeover.
   */

  L.TileLayer.Grayscale = L.TileLayer.extend({
    options: {
      quotaRed: 21,
      quotaGreen: 71,
      quotaBlue: 8,
      quotaDividerTune: 0,
      quotaDivider: function () {
        return (
            this.quotaRed +
            this.quotaGreen +
            this.quotaBlue +
            this.quotaDividerTune
        );
      },
    },

    initialize: function (url, options) {
      options.crossOrigin = true;
      L.TileLayer.prototype.initialize.call(this, url, options);

      this.on("tileload", (event) => {
        this._makeGrayscale(event.tile);
      });
    },

    _createTile: function () {
      let tile = L.TileLayer.prototype._createTile.call(this);
      tile.crossOrigin = "Anonymous";
      return tile;
    },

    _makeGrayscale: function (img) {
      if (img.getAttribute("data-grayscaled")) {
        return;
      }

      img.crossOrigin = "";
      let canvas = document.createElement("canvas");
      canvas.width = img.width;
      canvas.height = img.height;
      let context = canvas.getContext("2d");
      context.drawImage(img, 0, 0);

      let imgd = context.getImageData(0, 0, canvas.width, canvas.height);
      let pix = imgd.data;
      for (let index = 0, n = pix.length; index < n; index += 4) {
        pix[index] =
            pix[index + 1] =
                pix[index + 2] =
                    (this.options.quotaRed * pix[index] +
                        this.options.quotaGreen * pix[index + 1] +
                        this.options.quotaBlue * pix[index + 2]) /
                    this.options.quotaDivider();
      }
      context.putImageData(imgd, 0, 0);
      img.setAttribute("data-grayscaled", true);
      img.src = canvas.toDataURL();
    },
  });

  L.tileLayer.grayscale = function (url, options) {
    return new L.TileLayer.Grayscale(url, options);
  };
  L.tileLayer.colorfilter = function (url, options) {
    return new L.TileLayer.ColorFilter(url, options);
  };

  // all maps on the current page
  const maps = [];

  /**
   * A single map
   */
  class LeafletMap {
    /**
     * Creates a new leaflet map
     * @param {Object} data The map data
     */
    constructor(data) {
      const {
        lat,
        long,
        zoom,
        minZoom,
        maxZoom,
        attribution,
        layerUri,
        startupCategory,
      } = data.settings;

      // store information if LP global in module
      this.isLp = true;

      this.isLpv4 = false;
      if (
          document.querySelector("main").classList.contains("landingpage__v4")
      ) {
        this.isLpv4 = true;
      }

      this.nodeId = data.id;

      this.initialVals = {
        lat: parseFloat(lat),
        long: parseFloat(long),
        zoom: parseInt(zoom),
        minZoom: minZoom,
        maxZoom: maxZoom,
      };

      this.offset = {
        x: () => (this.isLp ? 0.9 : 0),
        y: () => (this.isLp ? 0 : 0),
      };

      // map shape (array of coordinates)
      this.shapes =
          typeof data.shapes !== "object" ? JSON.parse(data.shapes) : data.shapes;

      // array of all map markers
      this.markers =
          typeof data.markers !== "object"
              ? JSON.parse(data.markers)
              : data.markers;

      // console.log("1.5. Here we are, with offset x", this.offset.x);
      // console.log("2. Here we are, with the markers", data.markers);

      // array of map categories to be used in the legend
      this.mapCategories =
          typeof data.categories !== "object"
              ? JSON.parse(data.categories)
              : data.categories;

      // console.log("3. Here we are, with the categories", data.categories);
      // array of website categories to be used in the filter
      this.categories = this.mapCategories;

      // icon collection such as
      // key: 'categoryName',
      // value: 'iconPath'
      this.icons = {};

      // the current website filter id
      // initial select first category in categories

      this.selectedFilterID =
          this.categories[
              this.categories.findIndex(
                  (element) => parseInt(element.id) === parseInt(startupCategory)
              )
              ].id;

      // get theme from url
      const searchParameters = new URLSearchParams(window.location.search);

      if (searchParameters.has("theme")) {
        this.categories.forEach((category) => {
          if (
              category.name.toLowerCase() ===
              searchParameters.get("theme").toLowerCase()
          ) {
            this.selectedFilterID = category.id;
          }
        });
      }

      L.Map.addInitHook("addHandler", "gestureHandling", GestureHandling);

      // contains the currently visible map categories
      // (to be displayed in the legend)
      this.currentMapCategories = [];

      // Indicates whether the category filter is currently open or not
      this.filterOpen = false;

      // Represents the currently selected marker
      this.currentMarker = null;

      if (!this.isLp) {
        // TODO: remove all these eslint-disabled-next-line
        // eslint-disable-next-line unicorn/prefer-query-selector
        const mapNode = document.getElementById(data.id);
        const container = document.createElement("div");
        container.classList.add("container");
        container.classList.add("filterlayer");

        const row1 = document.createElement("div");
        row1.classList.add("row");
        row1.classList.add("row");
        container.appendChild(row1);

        const left = document.createElement("div");
        left.classList.add("col-sm-12");
        row1.appendChild(left);

        const button = document.createElement("div");
        button.classList.add("mappingtool__filterbutton");

        let responsiveText = document.createElement("span");
        responsiveText.appendChild(
            document.createTextNode(LeafletMap.getTranslations().chooseTopic)
        );
        button.appendChild(responsiveText);
        left.appendChild(button);

        const chooseTopic = document.createElement("div");
        chooseTopic.classList.add("mappingtool__topictext");
        chooseTopic.appendChild(
            document.createTextNode(LeafletMap.getTranslations().chooseTopic)
        );
        left.appendChild(chooseTopic);

        const topicList = document.createElement("div");
        topicList.classList.add("mappingtool__categorywrapper");
        topicList.classList.add("mappingtool__categorywrapper--hidden");
        if (this.isLp) {
          topicList.style.height = 0;
        }

        this.categories.forEach((category, index) => {
          // Remove Kampagne from Categories
          // Todo: not a brilliant way, find better method
          if (category.name !== "Kampagne") {
            const customID = `category-${index}-${category.id}`;
            const radioOuter = document.createElement("div");
            radioOuter.classList.add("mappingtool__categorySelector");

            const input = document.createElement("input");
            input.type = "radio";
            input.name = "map-topiclist";
            input.id = customID;
            if (this.selectedFilterID === category.id) {
              input.checked = true;
            }

            const label = document.createElement("label");
            label.setAttribute("for", customID);
            label.appendChild(document.createTextNode(category.name));

            input.addEventListener("click", (event) => {
              if (category.id === this.selectedFilterID) {
                // e.target.checked = false;
                // this.selectedFilterID = null;
              } else {
                let legend = document.querySelector(".mappingtool__legend");
                let parameters = `theme=${category.name}`;

                event.target.checked = true;
                this.selectedFilterID = category.id;

                chooseTopic.classList.remove("mappingtool__topictext--hidden");
                topicList.classList.add("mappingtool__categorywrapper--hidden");
                if (this.isLp) {
                  topicList.style.height = 0;
                }
                button.classList.remove("mappingtool__filterbutton--open");

                if (legend) {
                  legend.classList.remove("mappingtool__legend--hidden");
                }

                this.filterOpen = false;

                window.history.replaceState(
                    {},
                    "",
                    // eslint-disable-next-line no-restricted-globals
                    `${location.pathname}?${parameters}`
                );
              }
              this.applyFilter();
            });

            radioOuter.appendChild(input);
            radioOuter.appendChild(label);

            topicList.appendChild(radioOuter);
          }
        });

        left.appendChild(topicList);

        // insert container before map wrapper
        mapNode.parentNode.parentNode.insertBefore(
            container,
            mapNode.parentNode
        );

        button.addEventListener("click", () => {
          let legend = document.querySelector(".mappingtool__legend");

          if (this.filterOpen) {
            chooseTopic.classList.remove("mappingtool__topictext--hidden");
            topicList.classList.add("mappingtool__categorywrapper--hidden");
            if (this.isLp) {
              topicList.style.height = 0;
            }
            button.classList.remove("mappingtool__filterbutton--open");
            if (legend) {
              legend.classList.remove("mappingtool__legend--hidden");
            }
          } else {
            chooseTopic.classList.add("mappingtool__topictext--hidden");
            topicList.classList.remove("mappingtool__categorywrapper--hidden");
            if (this.isLp) {
              topicList.style.height = 0;
            }
            button.classList.add("mappingtool__filterbutton--open");
            if (legend && !this.isLp) {
              legend.classList.add("mappingtool__legend--hidden");
            }
          }
          this.filterOpen = !this.filterOpen;
        });
      } else {
        // eslint-disable-next-line unicorn/prefer-query-selector
        const mapNode = document.getElementById(data.id);
        const container = document.createElement("div");
        container.classList.add("container");
        container.classList.add("filterlayer");

        const row1 = document.createElement("div");
        row1.classList.add("row");
        row1.classList.add("row");
        container.appendChild(row1);

        const left = document.createElement("div");
        left.classList.add("mappingtool__filtercontainer");
        row1.appendChild(left);

        const buttonContainer = document.createElement("div");
        buttonContainer.classList.add("mappingtool__buttoncontainer");

        if (data.categories.length === 1) {
          buttonContainer.style.display = 'none';
        }

        const button = document.createElement("div");
        const ddButton = document.createElement("div");
        ddButton.classList.add("mappingtool__buttonarrow");
        /* eslint-disable */
        ddButton.innerHTML = `
      <svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 60 34.4" xml:space="preserve" width="26">
        <g>
          <polygon points="60,4.5 55.5,0.3 29.9,25.6 4.3,0 0,4.5 29.9,34.4" />
        </g>
      </svg>
      `;
        /* eslint-enable */
        button.classList.add("mappingtool__filterbutton");
        button.appendChild(ddButton);

        const topicTitle = document.createElement("div");
        topicTitle.classList.add("mappingtool__topictitle");
        left.appendChild(topicTitle);

        const chooseTopic = document.createElement("div");
        chooseTopic.classList.add("mappingtool__topictext");
        chooseTopic.appendChild(
            document.createTextNode(LeafletMap.getTranslations().chooseTopic)
        );
        buttonContainer.appendChild(chooseTopic);

        // let responsiveText = document.createElement('span');
        // responsiveText.appendChild(
        //   document.createTextNode(LeafletMap.getTranslations().chooseTopic)
        // );
        // button.appendChild(responsiveText);
        buttonContainer.appendChild(button);

        left.appendChild(buttonContainer);

        const topicList = document.createElement("div");
        topicList.classList.add("mappingtool__categorywrapper");
        topicList.classList.add("mappingtool__categorywrapper--hidden");
        if (this.isLp) {
          topicList.style.height = 0;
        }

        this.categories.forEach((category, index) => {
          // Remove Kampagne from Categories
          // Todo: not a brilliant way, find better method
          if (
              category.name !== "Kampagne" &&
              category.map_categories.length > 0
          ) {
            const customID = `category-${index}-${category.id}`;
            const radioOuter = document.createElement("div");
            radioOuter.classList.add("mappingtool__categorySelector");

            const input = document.createElement("input");
            input.type = "radio";
            input.name = "map-topiclist";
            input.id = customID;

            if (this.selectedFilterID === category.id) {
              input.checked = true;
              radioOuter.style.display = "none";
            }

            const label = document.createElement("label");
            label.setAttribute("for", customID);
            const parser = new DOMParser();
            const icon = parser.parseFromString(
                category.map_categories[0].icon_inline,
                "image/svg+xml"
            );
            label.appendChild(icon.firstChild);

            label.appendChild(document.createTextNode(category.name));

            ScrollTo.init({ speed: 2.5 });

            input.addEventListener("click", (event) => {
              let leftArray = Array.prototype.slice.call(
                  left.querySelectorAll(".mappingtool__categorySelector")
              );
              leftArray.forEach((otherSelector) => {
                setTimeout(() => {
                  otherSelector.style.display = "block";
                }, 500);
              });

              setTimeout(() => {
                radioOuter.style.display = "none";
              }, 501);

              if (category.id === this.selectedFilterID) {
                // e.target.checked = false;
                // this.selectedFilterID = null;
              } else {
                let legend = document.querySelector(".mappingtool__legend");
                let parameters = `theme=${category.name}`;
                let oldcategoryID = this.selectedFilterID;
                event.target.checked = true;
                this.selectedFilterID = category.id;
                let header = document.querySelectorAll(
                    ".headersection-element"
                );
                header.forEach(function (headerItem) {
                  headerItem.style.display = "none";
                });
                let categories = this.categories;
                let index;
                let oldcategory = "";
                for (index = 0; index < categories.length; index++) {
                  if (oldcategoryID === categories[index].id) {
                    oldcategory = categories[index].name;
                  }
                }

                oldcategory = oldcategory.toLowerCase();
                oldcategory = oldcategory.replace(/\u00E4/g, "ae");
                oldcategory = oldcategory.replace(/\u00F6/g, "oe");
                oldcategory = oldcategory.replace(/\u00FC/g, "ue");
                oldcategory = oldcategory.replace(/\u00DF/g, "ss");

                let newcategory = category.name;
                newcategory = newcategory.toLowerCase();
                newcategory = newcategory.replace(/\u00E4/g, "ae");
                newcategory = newcategory.replace(/\u00F6/g, "oe");
                newcategory = newcategory.replace(/\u00FC/g, "ue");
                newcategory = newcategory.replace(/\u00DF/g, "ss");

                let sociallinks = document.querySelectorAll(".social");
                for (let index = 0; index < sociallinks.length; index++) {
                  sociallinks[index].href = sociallinks[index].href.replace(
                      oldcategory,
                      newcategory
                  );
                  // console.log(sociallinks[i].href);
                }

                chooseTopic.classList.remove("mappingtool__topictext--hidden");
                topicList.classList.add("mappingtool__categorywrapper--hidden");
                if (this.isLp) {
                  topicList.style.height = 0;
                }
                button.classList.remove("mappingtool__filterbutton--open");

                if (legend && !this.isLp) {
                  legend.classList.remove("mappingtool__legend--hidden");
                }

                this.filterOpen = false;

                window.history.replaceState(
                    {},
                    "",
                    // eslint-disable-next-line no-restricted-globals
                    `${location.pathname}?${parameters}`
                );
              }
              this.applyFilter();
            });

            radioOuter.appendChild(input);
            radioOuter.appendChild(label);

            topicList.appendChild(radioOuter);
          }
        });

        document.addEventListener("click", (event) => {
          if (
              !(left.contains(event.target) || left === event.target) &&
              this.filterOpen
          ) {
            this.filterOpen = !this.filterOpen;
            button.classList.remove("mappingtool__filterbutton--open");
            topicList.classList.add("mappingtool__categorywrapper--hidden");
            if (this.isLp) {
              topicList.style.height = 0;
            }
          }
        });

        left.appendChild(topicList);

        // insert container before map wrapper
        mapNode.parentNode.parentNode.insertBefore(
            container,
            mapNode.parentNode
        );

        this.listModule = document.createElement("div");
        this.listModule.classList.add("mappingtool__contentlist");

        this.outerListModule = document.createElement("div");

        this.outerListModule.classList.add("mappingtool__contentlist_outer");

        this.outerListModule.appendChild(this.listModule);

        mapNode.parentNode.parentNode.appendChild(this.outerListModule);

        buttonContainer.addEventListener("click", () => {
          let legend = document.querySelector(".mappingtool__legend");

          if (this.filterOpen) {
            topicList.classList.add("mappingtool__categorywrapper--hidden");
            if (this.isLp) {
              topicList.style.height = 0;
            }
            button.classList.remove("mappingtool__filterbutton--open");
            if (legend && !this.isLp) {
              legend.classList.remove("mappingtool__legend--hidden");
            }
          } else {
            topicList.classList.remove("mappingtool__categorywrapper--hidden");
            if (this.isLp) {
              topicList.style.height = "auto";
            }
            button.classList.add("mappingtool__filterbutton--open");
            if (legend && !this.isLp) {
              legend.classList.add("mappingtool__legend--hidden");
            }
          }
          this.filterOpen = !this.filterOpen;
        });
      }

      const offset = {
        y: this.offset.y(),
        x: this.offset.x(),
      };

      if (
          window.getComputedStyle(this.outerListModule).position.toLowerCase() !==
          "absolute"
      ) {
        offset.x = 0;
        offset.y = 0;
      }

      this.map = L.map(data.id, {
        center: [
          this.initialVals.lat + offset.y,
          this.initialVals.long + offset.x,
        ],
        zoom:
            window.innerWidth >= 1000
                ? this.initialVals.zoom
                : this.initialVals.minZoom,
        gestureHandling: this.isLp,
        // Todo: get it from BE
        gestureHandlingOptions: {
          text: {
            touch: "Benutze zwei Finger um die Karte zu verschieben",
            scroll: "Benutze Strg + Scrollrad um die Karte zu zoomen",
            scrollMac: "Benutze \u2318 + Scrollrad um die Karte zu zoomen",
          },
          duration: 1500,
        },
        preferCanvas: true,
      });
      L.Map.addInitHook("addHandler", "gestureHandling", GestureHandling);
      L.control
          .zoom({
            position: "bottomright",
          })
          .addTo(this.map);

      if (this.isLp) {
        if (this.isLpv4) {
          let defaultFilter = [
            "blur:0px",
            "brightness:100%",
            "contrast:100%",
            "grayscale:0%",
            "hue:0deg",
            "opacity:100%",
            "invert:0%",
            "saturate:100%",
            "sepia:0%",
          ];
          let blueFilter = [
            "invert:63%",
            "brightness:190%",
            "sepia:100%",
            "hue:175deg",
            "saturate:150%",
          ];
          L.tileLayer
              .colorfilter(layerUri, {
                attribution,
                filter: blueFilter,
              })
              .addTo(this.map);
        } else {
          L.tileLayer
              .grayscale(layerUri, {
                attribution,
                minZoom,
                // TODO: layers are not being shown when maxZoom is set
                // maxZoom: maxZoom >= minZoom ? maxZoom : null,
              })
              .addTo(this.map);
        }
      } else {
        L.tileLayer(layerUri, {
          attribution,
          minZoom,
          // TODO: layers are not being shown when maxZoom is set
          // maxZoom: maxZoom >= minZoom ? maxZoom : null,
        }).addTo(this.map);
      }

      this.createIcons();
      this.addMarkers();
      this.addTitles()

      L.geoJSON(
          {
            type: "FeatureCollection",
            features: this.shapes,
          },
          {
            style: {
              color: this.isLpv4 ? "#ffffff" : "#0089c1",
              weight: this.isLp ? (this.isLpv4 ? 3 : 0) : 3,
              fillOpacity: this.isLp ? (this.isLpv4 ? 0.75 : 0.2) : 0.05,
              fillColor: this.isLpv4 ? "#52aadc" : "#0089c1",
            },
          }
      ).addTo(this.map);

      this.fixZoomButtons();
    }

    /**
     * Returns the translations object
     * @return {Object}
     */
    static getTranslations() {
      return (
          (window.mappingtoolSettings &&
              window.mappingtoolSettings.translations) ||
          {}
      );
    }

    /**
     * Creates icons for Map
     */
    createIcons() {
      this.mapCategories.forEach((siteCategory) => {
        siteCategory.map_categories.forEach((category) => {
          this.icons[category.icon_name] = L.divIcon({
            /* eslint-disable */
            html: `
            <span class="mappingtool__marker--shadow" style="background-image: url(../typo3conf/ext/ws_bootstraptheme_layout/Resources/Public/img/Mapmarker_shadow.png"></span>
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 90 117">
              <style>
                .st0{fill:#0389C2;}
              </style>
              <path d="M45 0C20.1 0 0 20.1 0 45c0 11 4 21.1 10.6 29L45 117l34.4-43C86 66.1 90 56 90 45 90 20.1 69.9 0 45 0zm0 72c-14.9 0-27-12.1-27-27s12.1-27 27-27 27 12.1 27 27-12.1 27-27 27z" class="st0"/>
              <path d="M45 45V17.8C60 17.8 72.2 30 72.2 45c0 5-1.1 9.3-3.7 13.6L45 45z" fill="#ffffff"/>
              <path d="M45 45L21.4 58.6c-7.5-13-3.1-29.7 10-37.2 4.3-2.5 8.6-3.7 13.6-3.7V45z" fill="#ffffff"/>
              <path d="M45 45l23.6 13.6c-7.5 13-24.2 17.5-37.2 10-4.3-2.5-7.5-5.6-10-10L45 45z" fill="#ffffff"/>
              <circle cx="45" cy="45" r="20" class="st4" fill="#ffffff"/>
            </svg>
            `,
            /* eslint-enable */
            iconSize: [40, 52],
            className: "mappingtool__marker",
          });
        });
      });
    }

    /**
     * Reinit the map markers
     */
    applyFilter() {
      this.map.removeLayer(this.currentLayer);
      this.addMarkers(true);
    }

    /**
     * Adds markers to the map
     * @param {boolean} zoomMap
     */
    addMarkers(zoomMap) {
      const data = this.markers;

      const markers = L.markerClusterGroup({
        iconCreateFunction: (cluster) =>
            L.divIcon({
              /* eslint-disable */
              html: `
            <span class="mappingtool__marker--shadow" style="background-image: url(../typo3conf/ext/ws_bootstraptheme_layout/Resources/Public/img/Mapmarker_shadow.png"></span>
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 90 117">
            <style>
            .st0{fill:#0389C2;} .st1{fill:#FECA05;} .st2{fill:#80C4E0;} .st3{fill:#C8D210;} .st4{fill:#FFFFFF;}
              </style>
              <path d="M45 0C20.1 0 0 20.1 0 45c0 11 4 21.1 10.6 29L45 117l34.4-43C86 66.1 90 56 90 45 90 20.1 69.9 0 45 0zm0 72c-14.9 0-27-12.1-27-27s12.1-27 27-27 27 12.1 27 27-12.1 27-27 27z" class="st0"/>
              <path d="M45 45V17.8C60 17.8 72.2 30 72.2 45c0 5-1.1 9.3-3.7 13.6L45 45z" class="st1"/>
              <path d="M45 45L21.4 58.6c-7.5-13-3.1-29.7 10-37.2 4.3-2.5 8.6-3.7 13.6-3.7V45z" class="st2"/>
              <path d="M45 45l23.6 13.6c-7.5 13-24.2 17.5-37.2 10-4.3-2.5-7.5-5.6-10-10L45 45z" class="st3"/>
              <circle cx="45" cy="45" r="20" class="st4"/>
              </svg>
              <span class="cluster-counter">${cluster.getChildCount()}</span>`,
              /* eslint-enable */
              iconSize: [40, 52],
              className: "mappingtool__clustermarker",
            }),
      });

      this.currentLayer = markers;

      // TODO: maybe refactor using new Set()
      let filterIDs = this.categories.find(
          (category) => category.id === this.selectedFilterID
      );

      const activeMarkers = [];

      if (data.length > 0) {
        data.forEach((marker) => {
          // helper function to build marker
          // (doesn't need info about marker caus it is in same loop)
          let buildMarker = () => {
            // if (filterIDs.includes(marker.map_entry_category.id) === -1) {
            //   filterIDs.push(marker.map_entry_category.id);
            // }
            const icon = this.icons[marker.icon_name];

            const mapMarker = L.marker(
                [parseFloat(marker.lat), parseFloat(marker.long)],
                {
                  icon,
                }
            );

            activeMarkers.push({ marker, mapMarker });

            mapMarker.on("click", () => {
              this.displayDetail(marker, mapMarker);
            });
            return mapMarker;
          };

          if (this.selectedFilterID === null) {
            markers.addLayer(buildMarker());
          } else {
            // go through all website categories
            this.categories.forEach((category) => {
              // if website category matches current filter
              if (category.id === this.selectedFilterID) {
                // go through all map categories
                category.map_categories.forEach((mapCategory) => {
                  // if marker category matches map category
                  if (marker.map_entry_category.id === mapCategory.id) {
                    markers.addLayer(buildMarker());
                  }
                });
              }
            });
          }
        });
      }

      this.buildPreviewList(activeMarkers);

      this.map.addLayer(markers);
      if (zoomMap === true && this.markers.length > 0 && !this.isLp) {
        let bounds = markers.getBounds();
        // correcting bounds because of header section
        bounds._northEast.lat = this.isLp
            ? bounds._northEast.lat
            : bounds._northEast.lat + 0.2;
        this.map.fitBounds(bounds);
      } else {
        const offset = {
          y: this.offset.y(),
          x: this.offset.x(),
        };

        if (
            window
                .getComputedStyle(this.outerListModule)
                .position.toLowerCase() !== "absolute"
        ) {
          offset.x = 0;
          offset.y = 0;
        }
        this.map.flyTo(
            [this.initialVals.lat + offset.y, this.initialVals.long + offset.x],
            window.innerWidth >= 1000
                ? this.initialVals.zoom
                : this.initialVals.minZoom
        );
      }
      this.currentMapCategories = filterIDs;
      this.updateLegend();
    }

    addTitles() {
     
      const allClusterMarkers = document.querySelectorAll('.mappingtool__clustermarker');
      const allMarkers = document.querySelectorAll('.mappingtool__marker');

      allClusterMarkers.forEach((clusterMarker, index) => {
        clusterMarker.setAttribute('title', `Cluster mit ${clusterMarker.querySelector('.cluster-counter').innerText} Standorteinträgen`);
      });

      allMarkers.forEach((marker, index) => {
        marker.setAttribute('title', `Zum Eintrag navigieren`);
      });
      // mappingtool__clustermarker

      // mappingtool__marker
    }

    /**
     * Builds a preview list for marker content
     * @param {*} markers
     * @param {*} clusterGroup
     */
    buildPreviewList(markers) {
      if (this.isLp) {
        // remove current content
        this.listModule.scrollTop = 0;
        this.listModule.innerHTML = "";

        const headline = document.createElement("h2");
        headline.classList.add("mappingtool_contentlist__headline");
        headline.innerHTML =
            // Todo: get text from BE
            (
                this.categories.find(
                    (category) => category.id === this.selectedFilterID
                ) || { name: "Thema" }
            ).name + " in Thüringen";
        this.listModule.appendChild(headline);

        const list = document.createElement("nav");
        list.classList.add("mappingtool_contentlist__list");

        if (markers.length > 0) {
          markers.forEach(({ marker, mapMarker }) => {
            const listItem = document.createElement("button");
            listItem.classList.add("mappingtool_contentlist__listitem");
            listItem.setAttribute("title", `Mehr Info zum ${marker.title}`);

            const imageContainer = document.createElement("div");
            imageContainer.classList.add("mappingtool_contentlist__itemimage");

            if (marker.image) {
              imageContainer.style["background-image"] = `url(${marker.image})`;
              // const image = document.createElement('img');
              // image.src = marker.image;
              // image.alt = marker.title;
              // imageContainer.appendChild(image);
            }
            listItem.appendChild(imageContainer);

            const itemContent = document.createElement("div");
            itemContent.classList.add("mappingtool_contentlist__itemcontent");

            const itemTitle = document.createElement("h3");
            itemTitle.classList.add("mappingtool_contentlist__itemtitle");
            itemTitle.innerHTML = marker.title;
            itemContent.appendChild(itemTitle);

            const itemText = document.createElement("div");
            itemText.classList.add("mappingtool_contentlist__itemtext");

            itemText.innerHTML = marker.description;

            if (marker.description.replace(/[^a-zA-Z]/g, "").length > 120) {
              let textHelper = itemText.innerText.slice(0, 120);
              textHelper = textHelper.replace(/\r?\n|\r/g, " ");
              if (textHelper.length > 50) {
                textHelper = textHelper
                    .slice(0, Math.max(0, textHelper.lastIndexOf(" ")))
                    .trim();
              }

              itemText.innerHTML = `<p class="line-clamp-3">${textHelper}${
                  textHelper.length < marker.description.trim().length
                      ? "..."
                      : ""
              }</p>`;
            }

            itemContent.appendChild(itemText);

            listItem.appendChild(itemContent);

            listItem.addEventListener("click", () => {
              const scrollToMarker = (scrollMarker) => {
                this.removeSelectedMarkers();

                this.map.panTo(
                    this.map.unproject(
                        this.map
                            .project(scrollMarker.getLatLng())
                            .subtract([
                              window
                                  .getComputedStyle(this.outerListModule)
                                  .position.toLowerCase() === "absolute"
                                  ? this.outerListModule.offsetWidth / -2
                                  : 0,
                              0,
                            ])
                    )
                );

                // set marker to selected styling when map finished movement
                this.map.once("moveend zoomend", () => {
                  if (scrollMarker._icon) {
                    scrollMarker._icon.classList.add(
                        "mappingtool__marker--selected"
                    );
                  }
                });
              };

              if (this.currentLayer.getVisibleParent(mapMarker)) {
                scrollToMarker(this.currentLayer.getVisibleParent(mapMarker));
              } else {
                scrollToMarker(mapMarker);
              }

              this.displayDetail(marker, mapMarker);
            });

            listItem.addEventListener("mouseover", () => {
              let scrollMarker = mapMarker;

              if (this.currentLayer.getVisibleParent(mapMarker)) {
                scrollMarker = this.currentLayer.getVisibleParent(mapMarker);
              }

              this.removeSelectedMarkers();

              if (scrollMarker._icon) {
                scrollMarker._icon.classList.add(
                    "mappingtool__marker--selected"
                );
              }
              //   const scrollToMarker = (scrollMarker) => {
              //     this.removeSelectedMarkers();

              //     this.map.panTo(
              //       this.map.unproject(
              //         this.map
              //           .project(scrollMarker.getLatLng())
              //           .subtract([
              //             window.getComputedStyle(this.outerListModule)
              //               .position.toLowerCase() === 'absolute'
              //               ? this.outerListModule.offsetWidth / -2
              //               : 0,
              //             0,
              //           ])
              //       )
              //     );

              //     // set marker to selected styling when map finished movement
              //     this.map.once('moveend zoomend', () => {
              //       if (scrollMarker._icon) {
              //         scrollMarker._icon.classList.add(
              //           'mappingtool__marker--selected'
              //         );
              //       } else {
              //         setTimeout(() => {
              //           scrollMarker._icon.classList.add(
              //             'mappingtool__marker--selected'
              //           );
              //         }, 500);
              //       }
              //     });
              //   };

              //   if (this.currentLayer.getVisibleParent(mapMarker)) {
              //     scrollToMarker(this.currentLayer
              //       .getVisibleParent(mapMarker));
              //   } else {
              //     scrollToMarker(mapMarker);
              //   }
            });

            listItem.addEventListener("mouseout", () => {
              if (!document.querySelector(".mappingtool__info--visible")) {
                this.removeSelectedMarkers();
              }
            });

            list.appendChild(listItem);
          });
        }

        this.listModule.appendChild(list);

        this.outerListModule.classList.add("scroll__fadeout");

        const addFadeIn = () => {
          this.outerListModule.classList.add("scroll__fadein");
          this.listModule.removeEventListener("ps-scroll-y", addFadeIn);
        };

        this.listModule.addEventListener("ps-y-reach-start", () => {
          this.outerListModule.classList.remove("scroll__fadein");
          this.listModule.addEventListener("ps-scroll-y", addFadeIn);
        });

        this.listModule.addEventListener("ps-scroll-y", addFadeIn);

        this.listModule.addEventListener("ps-y-reach-end", () => {
          const addFadeOut = () => {
            this.outerListModule.classList.add("scroll__fadeout");
            this.listModule.removeEventListener("ps-scroll-y", addFadeOut);
          };
          this.listModule.addEventListener("ps-scroll-y", addFadeOut);

          this.outerListModule.classList.remove("scroll__fadeout");
        });
      }
    }

    /**
     * Shows the Info Layer about a marker
     * @param {Object} markerData The marker data
     * @param {Object} mapMarker The marker from leaflet
     */
    displayDetail(markerData, mapMarker) {
      this.removeSelectedMarkers();

      if (mapMarker && mapMarker._icon) {
        mapMarker._icon.classList.add("mappingtool__marker--selected");
      } else if (this.currentLayer.getVisibleParent(mapMarker)) {
        this.currentLayer
            .getVisibleParent(mapMarker)
            ._icon.classList.add("mappingtool__marker--selected");
      }

      this.currentMarker = mapMarker;

      let currentNode = document.querySelector(".mappingtool__info");

      let article = document.createElement("article");
      article.classList.add("teaser");
      article.classList.add("teaser--external");
      article.classList.add("teaser--small");
      article.classList.add("mappingtool__info");

      let imagewrapper = document.createElement("div");
      imagewrapper.classList.add("teaser__image");

      let image = document.createElement("img");
      image.src = markerData.image;
      image.alt = markerData.title;
      imagewrapper.appendChild(image);
      article.appendChild(imagewrapper);

      let textwrapper = document.createElement("div");
      textwrapper.classList.add("teaser__text");
      textwrapper.classList.add("newsroomlink-zindexfix");

      let imageDescription = document.createElement("span");
      let imageDecsNew = "";
      let indexPlay = -1;
      if (markerData.imageDescription === null) {
        indexPlay = -1;
      } else {
        indexPlay = markerData.imageDescription.indexOf("play-mo-beel");
      }
      if (indexPlay !== -1) {
        imageDescription.classList.add("teaser__image__description");
        imageDescription.classList.add("play-mo-beel");
        imageDecsNew = markerData.imageDescription.replace(
            "play-mo-beel",
            'pl<span class="oveline_playmobil">a</span>y-m<span class="oveline_playmobil">o</span>-b<span class="oveline_playmobil">ee</span>l'
        );
      } else {
        imageDecsNew = markerData.imageDescription;
        imageDescription.classList.add("teaser__image__description");
      }
      imageDescription.innerHTML = imageDecsNew;
      article.appendChild(imageDescription);

      let textOnly = document.createElement("div");
      textOnly.classList.add("teaser__textOnly");

      let title = document.createElement("h3");
      title.classList.add("teaser__title");
      title.appendChild(document.createTextNode(markerData.title));
      textOnly.appendChild(title);

      let maxLetters;
      let headerPercent;

      if (window.innerWidth < 1200 && window.innerWidth > 576) {
        headerPercent = 60;
      } else {
        headerPercent = 28;
      }

      if (window.innerWidth < 740) {
        document.querySelector(".cookie-button").style.display = "none";
        document.querySelector(".back-to-top-btn").style.display = "none";
      }

      if (window.innerWidth < 1200) {
        let letterPerLine = Math.round((window.innerWidth - 33) / 11);
        let maxLines = Math.round(
            ((window.innerHeight / 100) * 95 -
                ((((window.innerHeight / 100) * 95) / 100) * headerPercent +
                    189 +
                    24)) /
            24
        );
        maxLetters = letterPerLine * maxLines;
      } else {
        maxLetters = 200;
      }

      if (this.isLp) {
        let text = document.createElement("p");
        if (
            markerData.description.replace(/[^a-zA-Z]/g, "").length > maxLetters
        ) {
          let textHelper = markerData.description.slice(
              0,
              Math.max(0, maxLetters)
          );

          textHelper = textHelper.replace(/\r?\n|\r/g, " ");
          if (textHelper.length > 50) {
            textHelper = textHelper
                .slice(0, Math.max(0, textHelper.lastIndexOf(" ")))
                .trim();
          }
          text.innerHTML = `${textHelper}${
              textHelper.length < markerData.description.trim().length
                  ? "..."
                  : ""
          }`;
        } else {
          let textHelper = markerData.description;
          text.innerHTML = textHelper;
        }
        textOnly.appendChild(text);
      }

      textwrapper.appendChild(textOnly);

      if (markerData.link) {
        let link = document.createElement("a");
        link.classList.add("btn");
        link.href = markerData.link;
        link.setAttribute("target", markerData.linkTarget);
        link.setAttribute("rel", "noopener");

        if (markerData.linkTitle) {
          link.appendChild(document.createTextNode(markerData.linkTitle));
        } else {
          if (this.isLp) {
            if (
                document
                    .querySelectorAll("MAIN")[0]
                    .classList.contains("landingpage__v2")
            ) {
              link.appendChild(document.createTextNode("Zur Webseite"));
            } else {
              link.appendChild(
                  document.createTextNode("Lesen Sie die ganze Geschichte")
              );
            }
          } else {
            link.appendChild(document.createTextNode("LOREM IPSUM"));
          }
        }


        textwrapper.appendChild(link);
      }


      // TODO: change the close button/modal
      const closeButton = document.createElement("span");
      closeButton.setAttribute("aria-label", "Close the info layer");
      closeButton.classList.add("mappingtool__closeButton");

      const closeButtonSVG = document.createElement("div");
      closeButtonSVG.classList.add("mappingtool__closeButtonSVG");

      closeButton.appendChild(closeButtonSVG);

      if (this.isLp) {
        /* eslint-disable */
        closeButtonSVG.innerHTML = `
      <button
                    title="Zurück zur Liste"
                    type="button"
                    class="popup-close close-button"
            ></button>`;
        /* eslint-enable */
        // Todo: get Text from BE
        //closeButton.appendChild(document.createTextNode("Zurück"));
      } else {
        closeButtonSVG.innerHTML = `
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48">
        <style>
          path.xIcon{fill:#333333;}
        </style>
        <path d="M48 44.2L27.8 24 48 3.8 44.2 0 24 20.2 3.8 0 0
        3.8 20.2 24 0 44.2 3.8 48 24 27.8 44.2 48z" class="xIcon"/>
      </svg>`;
      }

      if (this.isLp) {
        const closeButtonMobile = document.createElement("span");
        closeButtonMobile.setAttribute("aria-label", "Close the info layer");
        closeButtonMobile.classList.add("mappingtool__closeButtonMobile");
        closeButtonMobile.innerHTML = `
    <button
                    title="Zurück zur Liste"
                    aria-label="Zurück zur Liste"
                    type="button"
                    class="popup-close close-button"
            ></button>
    `;
        article.appendChild(closeButtonMobile);
        closeButtonMobile.addEventListener("click", () => {
          this.removeSelectedMarkers();

          document.querySelector(".cookie-button").style.display = "flex";
          document.querySelector(".back-to-top-btn").style.display = "flex";

          this.currentMarker = null;
          article.classList.remove("mappingtool__info--visible");
          article.parentNode.removeChild(article);
        });
      }

      article.appendChild(closeButton);
      article.appendChild(textwrapper);
      // eslint-disable-next-line unicorn/prefer-query-selector
      const mapNode = document.getElementById(this.nodeId);

      if (currentNode) {
        currentNode.parentNode.replaceChild(article, currentNode);
      } else {
        if (!this.isLp) {
          document
              .querySelector(".filterlayer .row > div")
              .appendChild(article);
        } else {
          mapNode.parentNode.parentNode.appendChild(article);
        }
      }

      if (window.innerWidth <= 1200) {
        setTimeout(() => {
          article.classList.add("mappingtool__info--visible");
        }, 1);
      } else {
        article.classList.add("mappingtool__info--visible");
      }

      closeButton.addEventListener("click", () => {
        if (this.currentMarker && this.currentMarker._icon) {
          this.currentMarker._icon.classList.remove(
              "mappingtool__marker--selected"
          );
        }

        if (mapMarker && mapMarker._icon) {
          mapMarker._icon.classList.remove("mappingtool__marker--selected");
        }
        this.currentMarker = null;
        article.classList.remove("mappingtool__info--visible");
        // setTimeout(() => {
        article.parentNode.removeChild(article);
        // }, 1);
      });
    }

    /**
     * Updates the legend in the top right corner of the map
     */
    updateLegend() {
      let currentNode = document.querySelector(".mappingtool__legend");
      let wrapper = document.createElement("div");
      wrapper.classList.add("mappingtool__legend");
      if (this.filterOpen) {
        wrapper.classList.add("mappingtool__legend--hidden");
      }

      let mobileHeader = document.createElement("div");
      mobileHeader.classList.add("mappingtool__mobileheader");
      mobileHeader.classList.add("mappingtool__mobileheader--hidden");
      mobileHeader.appendChild(document.createTextNode("Legende"));

      let ddArrowWrapper = document.createElement("div");
      ddArrowWrapper.innerHTML = `
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48">
    <path d="M38.9 20.4l-3.8-3.7-9.6
    9.5-9.6-9.6-3.7 3.8 13.3 13.3v.1z"></path>
  </svg>`;

      let ddArrow = ddArrowWrapper.querySelector("svg");

      mobileHeader.appendChild(ddArrow);

      mobileHeader.addEventListener("click", (event) => {
        event.preventDefault();
        event.stopPropagation();

        if (mobileHeader.classList.contains("legend--open")) {
          mobileHeader.classList.remove("legend--open");
        } else {
          mobileHeader.classList.add("legend--open");
        }

        mobileHeader.parentElement
            .querySelectorAll(".mappingtool__legenditem")
            .forEach((item) => {
              if (mobileHeader.classList.contains("legend--open")) {
                item.classList.add("mappingtool__legenditem--show");
              } else {
                item.classList.remove("mappingtool__legenditem--show");
              }
            });
      });

      wrapper.appendChild(mobileHeader);

      this.categories.forEach((sysCategory) => {
        if (
            this.selectedFilterID === sysCategory.id ||
            this.selectedFilterID === null
        ) {
          sysCategory.map_categories.forEach((category) => {
            if (category.id > -1) {
              let label = document.createElement("div");
              label.classList.add("mappingtool__legenditem");

              let icon = document.createElement("img");
              icon.setAttribute("alt", `The icon for ${category.name}.`);
              icon.src = category.icon;
              label.appendChild(icon);

              let text = document.createElement("span");
              let selectedTheme = document.createElement("div");
              selectedTheme.classList.add("mappingtool__legend--theme");
              selectedTheme.appendChild(document.createTextNode("Thema"));
              let selectedCategory = document.createElement("div");
              selectedCategory.classList.add("mappingtool__legend--category");
              selectedCategory.appendChild(
                  document.createTextNode(category.name)
              );

              text.appendChild(selectedTheme);
              text.appendChild(selectedCategory);

              label.appendChild(text);
              wrapper.appendChild(label);
            }
          });
        }
      });

      if (currentNode) {
        currentNode.parentNode.replaceChild(wrapper, currentNode);
      } else {
        document.querySelector(".mappingtool__topictitle").appendChild(wrapper);
      }
    }

    /**
     * Sets correct button position even on window resize
     */
    fixZoomButtons() {
      this.calculateZoomButtonPosition();

      window.addEventListener("resize", () => {
        this.calculateZoomButtonPosition();
      });
    }

    /**
     * Calculates correct right positioning for zoom buttons
     */
    calculateZoomButtonPosition() {
      // fix zoom position
      let filterContainer = document.querySelector(".filterlayer > .row");
      if (filterContainer) {
        let leafletControlsBottomRight = document.querySelectorAll(
            ".leaflet-bottom.leaflet-right > .leaflet-control"
        );
        let lastControlBottomRight =
            leafletControlsBottomRight[leafletControlsBottomRight.length - 2];
        let rightOffset =
            (document.querySelector("body").offsetWidth -
                filterContainer.offsetWidth) /
            2;

        lastControlBottomRight.style.right = `${rightOffset}px`;
      }
    }

    /**
     * Removes hover state from markers
     */
    removeSelectedMarkers() {
      const allHoveredMarkers = document.querySelectorAll(
          ".mappingtool__marker--selected"
      );

      [].forEach.call(allHoveredMarkers, (currentMarker) => {
        currentMarker.classList.remove("mappingtool__marker--selected");
      });
    }
  }

  if (window.mappingtoolIDs && Array.isArray(window.mappingtoolIDs)) {
    if (window.mappingtoolSettings.hideFooter) {
      document.querySelector("body").classList.add("no-padding-bottom");

      // make footer collapsible
      const footer = document.querySelector("footer.footer");
      footer.classList.add("footer--hidden");
      footer.classList.add("footer--fixed");

      const arrowSVG = `
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48">
    <path d="M38.9 20.4l-3.8-3.7-9.6
    9.5-9.6-9.6-3.7 3.8 13.3 13.3v.1z"/>
  </svg>`;

      const toggleFooterButton = document.createElement("button");
      toggleFooterButton.classList.add("footer__toggle");
      toggleFooterButton.innerHTML = `${
          LeafletMap.getTranslations().openFooter
      } ${arrowSVG}`;

      const container = document.createElement("div");
      container.classList.add("container");
      container.appendChild(toggleFooterButton);

      footer.insertBefore(container, footer.firstChild);

      toggleFooterButton.addEventListener("click", () => {
        footer.classList.toggle("footer--hidden");
        if (footer.classList.contains("footer--hidden")) {
          toggleFooterButton.innerHTML = `${
              LeafletMap.getTranslations().openFooter
          } ${arrowSVG}`;
        } else {
          toggleFooterButton.innerHTML = `${
              LeafletMap.getTranslations().closeFooter
          } ${arrowSVG}`;
        }
      });
    }

    window.mappingtoolIDs.forEach((singleMap) => {
      const wrapper = document.createElement("div");
      wrapper.classList.add("map-wrapper");
      // eslint-disable-next-line unicorn/prefer-query-selector
      const map = document.getElementById(singleMap.id);
      map.parentNode.insertBefore(wrapper, map);
      map.parentNode.removeChild(map);
      wrapper.appendChild(map);
      maps.push(new LeafletMap(singleMap));
    });
  }
}
