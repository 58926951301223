export const initMessages = async (iterator) => {
    localStorage.setItem('lastFetch', Date.now());
    const container = document.querySelector('.tdde__content-wrap')
    const url = window.location.origin + '/?type=1337';
    const messageList = document.querySelector('#quotes-listing');
    const intervall = 15;

    if(!container) return;
    if(messageList?.length < 1) return;

    iterator += 1;
    fetch(url)
    .then((resp) => resp.json())
    .then(function(data) {
        const lastfetchTs = localStorage.getItem('lastFetch') ?? 0;
        const quotes = data.messages;
        let countItems = Object.keys(quotes).length;
        if ((iterator + intervall) > countItems) {
            iterator = 0;
        }
        let countTo = iterator + intervall;
        // console.log(iterator + ' - ' + countTo);
        // fadeOut(messageList,100);
        messageList.style.opacity = 0.5;
        const bubbles = quotes.slice(iterator,countTo).map(message => {
            let bubble = document.createElement('article');
            bubble.innerHTML = '<div class="article-text">... ' + message.message + '</div><div class="article-author"><blockquote class="author">' + message.writer + '</blockquote></div>';
            return bubble;
        });
        messageList.innerHTML ='';
        messageList.prepend(...bubbles);
        fadeIn(messageList,800);
        localStorage.setItem('lastFetch', Date.now());
    })
    .catch(function(error) {
        console.log(error);
    });
    await Sleep(31900);
    initMessages(iterator);
};

function Sleep(milliseconds) {
    return new Promise(resolve => setTimeout(resolve, milliseconds));
}

export const fadeIn = (element, duration) => {
    (function increment(value = 0.5) {
        element.style.opacity = String(value);
        if (element.style.opacity !== '1') {
            setTimeout(() => {
                increment(value + 0.1);
            }, duration / 10);
        }
    })();
};

export const fadeOut = (element, duration) => {
    (function decrement() {
        (element.style.opacity -= 0.1) < 0 ? element.style.display = 'none' : setTimeout(() => {
            decrement();
        }, duration / 10);
    })();
};
