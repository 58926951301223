import { MAX_ELEMENTS_UNTIL_PAGINATION } from "./constants";

/**
 * Creates a compact pagination for a given splide instance
 * @param splide
 * @returns {void}
 * @see https://splidejs.com/
 *
 */

export const initUseCompactPagination = (splide) => {
  splide.on("pagination:mounted", (data) => {
    if (splide.length <= MAX_ELEMENTS_UNTIL_PAGINATION) return;

    data.list.innerHTML = `<div class="slider__pagination">
            <button class="splide-arrow--prev">&lt;</button>
            <span class="splide-position">${splide.index + 1}/${
      splide.length
    }</span>
            <button class="splide-arrow--next">&gt;</button>
          </div>`;

    const buttonPrevious = data.list.querySelector(".splide-arrow--prev");
    const buttonNext = data.list.querySelector(".splide-arrow--next");

    buttonPrevious.addEventListener("click", () => {
      splide.go("-1");
      if (splide.index === 0) {
        splide.go(splide.length - 1);
      }
    });

    buttonNext.addEventListener("click", () => {
      splide.go("+1");
      if (splide.index === splide.length - 1) {
        splide.go(0);
      }
    });
  });

  splide.on("moved", () => {
    if (splide.length <= MAX_ELEMENTS_UNTIL_PAGINATION) return;

    const position = splide.root.querySelector(".splide-position");
    if (!position) return;
    position.innerHTML = `${splide.index + 1}/${splide.length}`;
  });
};
