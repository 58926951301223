import smoothscroll from "smoothscroll-polyfill";
import { $$ } from "./utils";

export async function initSmoothScroll() {
  smoothscroll.polyfill();

  $$(".scrollToTop").forEach(function (scrollButton) {
    scrollButton.addEventListener("click", function () {
      scrollToTop();
    });
  });
}

function initScrollButton() {
  const backToTopButton = document.querySelector(".scrollToTop");
  backToTopButton.style.display = "none";
}

function scrollTopButtonAppear() {
  const backToTopButton = document.querySelector(".scrollToTop");
  if (window.pageYOffset > 50) {
    if (!backToTopButton.classList.contains("back-to-top-btn-entry")) {
      backToTopButton.classList.remove("back-to-top-btn-exit");
      backToTopButton.classList.add("back-to-top-btn-entry");
      backToTopButton.style.display = "block";
    }
  } else {
    if (backToTopButton.classList.contains("back-to-top-btn-entry")) {
      backToTopButton.classList.remove("back-to-top-btn-entry");
      backToTopButton.classList.add("back-to-top-btn-exit");
      setTimeout(function () {
        backToTopButton.style.display = "none";
      }, 250);
    }
  }
}

initScrollButton();
window.addEventListener("scroll", scrollTopButtonAppear);

function scrollToTop() {
  window.scroll({ top: 0, left: 0, behavior: "smooth" });
}
