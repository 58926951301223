import { initCSSChecker } from "../cssChecker";

export const initScrollToBasket = () => {
  const cookieButton = document.querySelector(".cookie-button");
  const shopModalBasket = document.querySelector(".shop__modal--basket");
  const windowWidth = window.innerWidth;

  const articleToBasketButtons = document.querySelectorAll(".btn-basket");

  if (shopModalBasket && windowWidth < 768) {
    const shopModalBasketButton = shopModalBasket.querySelector("button");
    articleToBasketButtons.forEach((button) => {
      // Add behaviour when adding one article to the basket
      button.addEventListener("click", () => {
        shopModalBasket.classList.add("active");
        const amount = document.querySelectorAll(".shopping-item");
        document.querySelector(
          ".shop__modal--basket-number"
        ).innerHTML = `Warenkorb - ${amount.length + 1} Stück`;
        cookieButton.classList.remove("active");
      });
    });

    if (shopModalBasket.classList.contains("active")) {
      cookieButton.classList.remove("active");
    } else {
      cookieButton.classList.add("active");
    }

    shopModalBasketButton.addEventListener("click", () => {
      document
        .querySelector(".shopping-items")
        .scrollIntoView({ behavior: "smooth" });
      shopModalBasket.classList.remove("active");
      cookieButton.classList.add("active");
    });
  }
};

export const initOpenDownloadShopModal = () => {
  const cookieButton = document.querySelector(".cookie-button");
  const articles = document.querySelectorAll(".tx-ws-dit-shop .article");
  if (articles) {
    articles.forEach((article) => {
      const openDownloadModalButton = article.querySelector(".btn-download");
      const downloadModal = article.querySelector(".article-download-modal");
      const heroSectionModal = document.querySelector(
        ".hero-download-modal-wrap"
      );
      const closeModalButton = article.querySelector(
        ".article-download-close-button"
      );

      if (downloadModal.classList.contains("active")) {
        cookieButton.classList.remove("active");
      } else {
        cookieButton.classList.add("active");
      }

      // Variables for the download of the item
      const downloadItemButton = article.querySelector(".btn-download-item");
      const itemLanguageSelector = article.querySelectorAll(
        'input[name="item-language"]'
      );

      openDownloadModalButton.addEventListener("click", () => {
        // First, close all the other modals (articles and hero) that could be open
        document
          .querySelectorAll(".article-download-modal")
          .forEach((modal) => {
            modal.classList.remove("active");
          });
        if (heroSectionModal) {
          document
            .querySelector(".hero-download-modal-wrap")
            .classList.remove("active");
        }

        downloadModal.classList.add("active");
        cookieButton.classList.remove("active");
        setTabindex(article, 0);
      });
      closeModalButton.addEventListener("click", () => {
        downloadModal.classList.remove("active");
        if (itemLanguageSelector) {
          itemLanguageSelector.forEach((languagePill) => {
            languagePill.checked = false;
          });
        }
        downloadItemButton.classList.add("btn-disabled");
        setTabindex(article, -1);
      });

      // Cancel download possibility if no radio button is selected
      downloadItemButton.addEventListener("click", (event) => {
        if (downloadItemButton.classList.contains("btn-disabled")) {
          event.preventDefault();
        }
      });

      if (itemLanguageSelector) {
        itemLanguageSelector.forEach((languagePill) => {
          languagePill.addEventListener("click", () => {
            itemLanguageSelector.forEach((pill) => {
              // pill.setAttribute("aria-checked", "false");
            });
            if (languagePill.checked === true) {
              let downloadData = languagePill.getAttribute("data-download");
              // languagePill.setAttribute("aria-checked", "true");
              downloadItemButton.setAttribute("download", downloadData);
              downloadItemButton.setAttribute("href", downloadData);
              downloadItemButton.classList.remove("btn-disabled");
            } else {
              downloadItemButton.classList.add("btn-disabled");
            }
          });
        });
      }
    });
  }
};

export const initAddCartButton = () => {
  submitButtonEnableOrDisable(true);
  document.querySelectorAll(".btn-basket").forEach((element) => {
    element.addEventListener("click", () => {
      addToCart(element, "de");
      submitButtonEnableOrDisable(false);
    });
  });
  //If User clicked "Angaben ändern"
  setCartItems();
};

function addToCart(
  item,
  defaultLanguage,
  quantity = false,
  check = true,
  customProductId = null
) {
  // Create the item
  const shoppingItem = document.createElement("div");
  shoppingItem.classList.add("shopping-item");
  if (check) {
    if (
      checkExistingId(defaultLanguage + "-" + item.dataset.productId)
    ) {
      let items = [];
      if (item.dataset.articleList) {
        items = JSON.parse(item.dataset.articleList);
      }

      let checkExistingId;
      for (let index = 0; index < items.length; index++) {
        // checkExistingId = `[product-id="${items[index].id}"]`;
        checkExistingId = `#${items[index].variationLabel}-${items[index].id}`;
        const searchElement = document.querySelector(checkExistingId);
        if (!searchElement) {
          addToCart(
            item,
            items[index].variationLabel,
            false,
            false,
            items[index].id
          );
          return;
        }
      }
      return;
    }
  }
  if (!customProductId) {
    shoppingItem.setAttribute(
      "id",
      defaultLanguage + "-" + item.dataset.productId
    );
  } else {
    shoppingItem.setAttribute("id", defaultLanguage + "-" + customProductId);
  }

  shoppingItem.dataset.articleList = item.dataset.articleList;

  const figure = document.createElement("figure");

  // Dealing with the item img
  let itemImg = document.createElement("img");
  itemImg.setAttribute(
    "src",
    item.dataset.productSrc || item.dataset.productVideoImage
  );
  // console.log(item);
  itemImg.setAttribute("alt", item.dataset.productTitle || 'test');
  figure.appendChild(itemImg);

  // Dealing with the item title
  const content = document.createElement("div");
  content.classList.add("content");
  const title = document.createElement("p");
  title.innerHTML = item.dataset.productTitle;
  content.appendChild(title);

  const dropdowns = document.createElement("div");
  dropdowns.classList.add("dropdowns");

  const selectLanguage = document.createElement("select");
  selectLanguage.setAttribute("id", "language");
  selectLanguage.setAttribute("name", "language");

  const articleList = JSON.parse(item.dataset.articleList);

  const existList = [];

  for (let article in articleList) {
    const id =
      articleList[article].variationLabel + "-" + articleList[article].id;
    if (!document.querySelector(`#${id}`)) {
      selectLanguage.options[selectLanguage.options.length] = new Option(
        articleList[article].variationLabel,
        articleList[article].variationLabel
      );
    } else {
      existList.push(id);
    }
  }
  if (defaultLanguage) {
    let list = [...selectLanguage.options];
    list.forEach((element, index) => {
      if (element.value === defaultLanguage) {
        selectLanguage.options[index].selected = true;
      }
    });
  } else selectLanguage.options[0].selected = true;

  dropdowns.appendChild(selectLanguage);

  // Dealing with the amount selection
  const selectAmount = document.createElement("select");
  selectAmount.setAttribute("id", "amount");
  selectAmount.setAttribute("name", "amount");

  let quantities = {
    0: "0",
    1: "1",
    2: "2",
    3: "3",
    4: "4",
    5: "5",
  };

  for (let quantity in quantities) {
    selectAmount.options[selectAmount.options.length] = new Option(
      quantities[quantity],
      quantity
    );
  }
  if (quantity) selectAmount.options[quantity].selected = true;
  else {
    selectAmount.options[1].selected = true;
  }
  dropdowns.appendChild(selectAmount);
  content.appendChild(dropdowns);
  shoppingItem.appendChild(figure);
  shoppingItem.appendChild(content);

  const addField = document.querySelector(".shopping-items");
  addField.appendChild(shoppingItem);

  if (existList.length > 0) updateSelectFromOtherItems(existList);

  // Put the selected item in the basket

  synchronizeItem(shoppingItem);
  checkLanguage(selectLanguage, shoppingItem);
  checkAmount(selectAmount, shoppingItem.getAttribute("id"), shoppingItem);
}

const checkAmount = (item, id, element) => {
  item.addEventListener("change", () => {
    if (item.value === "0") {
      resetSelectFromOtherItems(element.getAttribute("id"));
      checkExistingItems();
    }
    synchronizeItem(element);
  });
};
const synchronizeItem = (element) => {
  const id = element.getAttribute("id");
  const item = document.querySelector(`[data-sync="${id}"]`);
  if (item) {
    item.value = element.querySelector("#amount").value;
  }
};

const synchronizeResetItem = (id) => {
  document.querySelector(`[data-sync="${id}"]`).value = 0;
};

const checkLanguage = (item, element) => {
  item.addEventListener("change", () => {
    const articleList = JSON.parse(element.dataset.articleList);
    const index = articleList.findIndex(
      (element_) => element_.variationLabel === item.value
    );
    if (index !== -1) {
      const id =
        articleList[index].variationLabel + "-" + articleList[index].id;
      const checkExist = document.querySelector(`#${id}`);
      if (!checkExist) {
        synchronizeResetItem(element.getAttribute("id"));
        element.setAttribute(
          "id",
          articleList[index].variationLabel + "-" + articleList[index].id
        );
        synchronizeItem(element);
      }
    }
  });
};

const checkExistingId = (id) => {
  const checkId = document.querySelector(`#${id}`);
  if (checkId) return true;
};

//if several of the same items are selected then reduce their available language
const updateSelectFromOtherItems = (array) => {
  array.forEach((id) => {
    const item = document.querySelector(`#${id}`);
    const articleList = JSON.parse(item.dataset.articleList);
    const select = item.querySelector("#language");
    for (let index = 0; index < select.length; index++) {
      if (select.options[index].value !== select.value) {
        select.remove(index);
      }
    }

    for (let article in articleList) {
      const id =
        articleList[article].variationLabel + "-" + articleList[article].id;
      if (!document.querySelector(`#${id}`)) {
        select.options[select.options.length] = new Option(
          articleList[article].variationLabel,
          articleList[article].variationLabel
        );
      }
    }
  });
};
//If item get removed then the same item with different language unlocks that language
const resetSelectFromOtherItems = (itemId) => {
  const element = document.querySelector(`#${itemId}`);
  const id = itemId.split("-")[1];
  const articleList = JSON.parse(element.dataset.articleList);
  const list = articleList.filter((element_) => element_.id !== id);
  const array = list.map(
    (element_) => element_.variationLabel + "-" + element_.id
  );
  element.remove();
  array.forEach((id) => {
    const item = document.querySelector(`#${id}`);
    if (!item) return;
    const select = item.querySelector("#language");
    for (let article in articleList) {
      const id =
        articleList[article].variationLabel + "-" + articleList[article].id;
      if (!document.querySelector(`#${id}`)) {
        select.options[select.options.length] = new Option(
          articleList[article].variationLabel,
          articleList[article].variationLabel
        );
      }
    }
  });
};

//Send Order
export const verifyOrder = () => {
  const buttons = document.querySelectorAll(".verifyOrder");
  if (buttons && buttons.length > 0) {
    buttons.forEach((button) => {
      button.addEventListener("click", (event) => {
        const formData = JSON.parse(button.getAttribute("formData"));
        const items = JSON.parse(button.getAttribute("items"));
        const url = button.getAttribute("url");
        const data = {
          formData,
          items,
        };
        //eslint-disable-next-line
        fetch(`${url}&tx_wsditshop_shop[order]=${JSON.stringify(data)}`, {
          credentials: "include",
        })
          .then((response) => response.json())
          .then((data) => {
            if (data.success) {
              handleResponse(true);
            }
          })
          .catch((error) => {
            console.error(error);
            handleResponse(false);
          });
      });
    });
  }
};
//Set previous selected Items if clicked "Angaben ändern"
const setCartItems = () => {
  let items = document.querySelector(".article__fullwidth");

  let isCSSEnabled = initCSSChecker();
  if (!isCSSEnabled) {
    items.hidden = true;
    document.querySelector(".shop__modal--basket").hidden = true;
  }

  if (items && items.dataset.items !== "null" && typeof items.dataset.items !== 'undefined') {
    items = JSON.parse(items.dataset.items);
    submitButtonEnableOrDisable(false);
  } else return;
  let allArticles = document.querySelectorAll(".btn-basket");
  let resultJson = [];
  let element;
  let language;
  if (allArticles) {
    allArticles.forEach((element) => {
      resultJson = [
        ...resultJson,
        ...JSON.parse(element.dataset.articleList),
      ];
    });
    for (let key in items) {
      for (let searchedItem of resultJson) {
        if (searchedItem.id === Number(key)) {
          allArticles.forEach((element_) => {
            const articles = JSON.parse(element_.dataset.articleList);
            articles.forEach((jsonData) => {
              if (jsonData.id === Number(key)) {
                element = element_;
                language = jsonData.variationLabel;
              }
            });
          });
          addToCart(element, language, Number(items[key]), false, Number(key));
        }
      }
    }
  }
};

const handleResponse = (response) => {
  if (response) {
    window.location.href = window.location.origin + "/bestellung-erfolgreich/";
  } else {
    console.error("FEHLGESCHLAGEN");
  }
};

const submitButtonEnableOrDisable = (bool) => {
  const submitButton = document.querySelector(".btn-submit-shop");
  if (submitButton) submitButton.disabled = bool;
};

const checkExistingItems = () => {
  const shoppingItems = document.querySelector(".shopping-items");
  if (shoppingItems && !shoppingItems.hasChildNodes()) {
    submitButtonEnableOrDisable(true);
  }
};

const setTabindex = (element, value) => {
  const inputs = element.querySelectorAll(".article-download-modal input");
  const anchors = element.querySelectorAll(".article-download-modal a");
  const buttons = element.querySelectorAll(".article-download-modal button");

  const listOfAll = [...inputs, ...anchors, ...buttons];
  listOfAll.forEach((element) => {
    if (element.tabIndex !== value) {
      element.tabIndex = value;
    }
  });
};

export const hideItemsWhenNoCSS = () => {
  const itemsBasket = document.querySelector(".shop__wrapper");
  // console.log(itemsBasket);
};
