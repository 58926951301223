// @ts-check

/**
 * A quicker `.querySelector` for a single element
 *
 * @param {string} selector The element selector
 * @param {ParentNode} [parent=document] Where to start searching from
 *
 * @return {Element|null} The query result
 *
 * @example
 * const header = $('header.page-header');
 *
 */
export const $ = (selector, parent = document) => {
  return parent.querySelector(selector);
};

/**
 * A quicker `.querySelectorAll`, which returns an array instead
 * of a NodeList, meaning .forEach is usable in IE11 without the
 * need to either polyfill NodeList.forEach or turning the NodeList
 * into an Array manually.
 *
 * @param {string} selector The element selector
 * @param {*} [parent=document] Where to start searching from
 *
 * @return {Element[]} The query result
 *
 * @example
 * $$('p').forEach((paragraph) => {
 *     console.log(paragraph.innerText);
 * });
 */
export const $$ = (selector, parent = document) => {
  return [].slice.call(parent.querySelectorAll(selector));
};
