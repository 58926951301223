export function initCSSChecker() {
  let isCSSEnabled = false; // must be proven otherwise
  let testcss = document.createElement("div");
  testcss.style.position = "absolute";
  document.querySelectorAll("body")[0].appendChild(testcss);
  let currstyle;

  if (testcss.currentStyle) {
    currstyle = testcss.currentStyle["position"];
  } else if (window.getComputedStyle) {
    currstyle = document.defaultView
      .getComputedStyle(testcss, null)
      .getPropertyValue("position");
  }

  isCSSEnabled = currstyle === "static" ? false : true;
  document.querySelectorAll("body")[0].removeChild(testcss);
  return isCSSEnabled;
}
