import { STAGING_HOSTNAME } from "../config/constants";
import { $, $$ } from "./utils";

export const initNavigation = async () => {
  initSubmenu();
  initAccessibilityMegaMenu();
  const naviToggler = $(".navigation-toggle-hamburger-wrapper");

  if (naviToggler) {
    let ariaState = naviToggler.firstElementChild.getAttribute("aria-expanded");
    const naviOpen = $(".navigation-open");
    naviToggler.addEventListener("click", () => {
      naviOpen.classList.add("active");
      naviOpen.setAttribute("aria-hidden", false);
      ariaState = true;
      naviToggler.firstElementChild.setAttribute("aria-expanded", ariaState);
      const navs = document.querySelectorAll(
        ".navigation-secondary .nav-list-items li"
      );
      if (navs && navs.length > 0) {
        navs.forEach((element) => {
          const anchor = element.querySelector("a");
          anchor.tabIndex = "0";
        });
      }
      const closeButton = document.querySelector(
        ".close-nav-btn.close-secondary-nav-btn"
      );
      if (closeButton) closeButton.tabIndex = "0";
    });

    const closeNavButtons = $$(".close-nav-btn");
    closeNavButtons.forEach((button) => {
      button.addEventListener("click", () => {
        naviOpen.classList.remove("active");
        naviOpen.setAttribute("aria-hidden", true);
        ariaState = false;
        naviToggler.firstElementChild.setAttribute("aria-expanded", ariaState);
        const navs = document.querySelectorAll(
          ".navigation-secondary .nav-list-items li"
        );
        if (navs && navs.length > 0) {
          navs.forEach((element) => {
            const anchor = element.querySelector("a");
            anchor.tabIndex = "-1";
          });
        }
        const closeButton = document.querySelector(
          ".close-nav-btn.close-secondary-nav-btn"
        );
        if (closeButton) closeButton.tabIndex = "-1";
      });
    });
  }
};

const initSubmenu = () => {
  const dropdownLinks = $$(".dropdown-link");
  dropdownLinks.forEach((link) => {
    const dropdown = link.querySelector(".dropdown-menu");
    const dropdownContent = link.querySelector(".dropdown-menu__content");
    link.addEventListener("click", (event) => {

      const isLink =
        event.target.tagName === "A" ||
        event.target.parentElement.tagName === "A" ||
        event.target.parentElement.parentElement.tagName === "A" ||
        event.target.parentElement.parentElement.parentElement.tagName === "A";

      if (isLink) return; // Do nothing if clicked on link
      event.preventDefault();
      // if clicked on button, do nothing
      if (event.target.tagName === "BUTTON") return;

      dropdown.classList.add("active");
      dropdownContent.classList.add("active");
    });

    const dropdownToggleMobile = link.querySelector(".dropdown-toggle");

    if (dropdownToggleMobile) {
      dropdownToggleMobile.addEventListener("click", (event) => {
        event.preventDefault();
        dropdown.classList.toggle("active");
        dropdownContent.classList.toggle("active");
      });
    }

    const closeButtonList = link.querySelectorAll(".close-dropdown-btn");

    closeButtonList.forEach((closeButton) => {
      closeButton.addEventListener("click", (event) => {
        event.preventDefault();
        dropdown.classList.remove("active");
        dropdownContent.classList.remove("active");
      });
    });
  });

  //disable context menu on staging and production
  const url = window.location

  if(process.env.NODE_ENV !== "development" && url.hostname !== STAGING_HOSTNAME) {
    window.addEventListener("contextmenu", error => error.preventDefault());
  }
};

/**
 * This function initializes the accessibility for the mega menu for bigger screens
 * It adds the aria-haspopup and aria-expanded attributes to the dropdown toggle buttons
 * @returns void
 */
const initAccessibilityMegaMenu = () => {
  const dropdownToggleDesktop = document.querySelectorAll('.dropdown-toggle-desktop');
  const navListItems = document.querySelector('.nav-list-items');

  if (!dropdownToggleDesktop.length || !navListItems) return;

  function closeAllDropdownMenus() {
    document.querySelectorAll('.dropdown-menu__content').forEach(dropdownContent => {
      dropdownContent.classList.remove('flex');
      document.querySelectorAll('.dropdown-toggle-desktop').forEach(toggle => {
        toggle.setAttribute('aria-expanded', 'false');
      });

      document.querySelectorAll('.dropdown-menu__overlay').forEach(overlay => {
        overlay.classList.remove('bg-menu-overlay-color');
      });
    });
  }

  dropdownToggleDesktop.forEach(button => {
    const dropdownMenu = button.nextElementSibling;
    if (!dropdownMenu) {
      console.log('no dropdownMenu');
      return;
    }

    const dropdownMenuOverlay = dropdownMenu.querySelector('.dropdown-menu__overlay');
    const dropdownMenuContent = dropdownMenu.querySelector('.dropdown-menu__content');
    if (!dropdownMenuContent || !dropdownMenuOverlay) {
      console.log('no dropdownMenuContent or dropdownMenuOverlay');
      return;
    }

    button.setAttribute('aria-haspopup', 'true');
    button.setAttribute('aria-expanded', 'false');

    button.addEventListener('keydown', (event) => {
      if (event.key === 'Enter' || event.key === ' ') {
        event.preventDefault();
        const isOpen = dropdownMenuContent.classList.contains('flex');
        closeAllDropdownMenus();
        if (!isOpen) {
          dropdownMenuContent.classList.add("flex");
          dropdownMenuOverlay.classList.add("bg-menu-overlay-color")
          button.setAttribute('aria-expanded', 'true');
        }
      }
    });
  });

  // Apply focus event listener to all links within the mega menu
  document.querySelectorAll('.nav-item .nav-link').forEach(link => {
    link.addEventListener('focus', function() {
      // Close all dropdown menus when focusing on any link
      closeAllDropdownMenus();
    });
  });

  document.addEventListener('focusin', (event) => {
    // Check if the newly focused element is outside the nav-list-items
    if (!navListItems.contains(event.target)) {
      closeAllDropdownMenus();
    }
  });
};

// initAccessibilityMegaMenu();

