export default class ThreeColsCardElement {

  element;

  /**
   * 
   * @param {Node} element
   */
  constructor(element) {
    this.setElement(element);
    this.resetElement();
  }

  /**
   * 
   * @param {string} url 
   * @return {ThreeColsCardElement}
   */
  setUrl(url) {
    this.element.href = url;
    return this;
  }

  /**
   * 
   * @param {string} url 
   * @return {ThreeColsCardElement}
   */
  setImg(img) {
    const imgNode = this.element.querySelector('img');
    imgNode.src = img.imageUrl;
    imgNode.alt = img.imageAlt ?? '';
    imgNode.title = img.imageTitle ?? '';

    return this;
  }

  /**
   * 
   * @param {string} header 
   * @return {ThreeColsCardElement}
   */
  setHeader(header) {
    this.element.querySelector('.card__content__title').innerText = header;

    return this;
  }

  setTag(subtitle) {
    this.element.querySelector('.card__content__category').innerText = subtitle;

    return this;
  }

  setSubtitle(teaser) {
    const MAX_CHARACTERS = 100;
    const ellipsis = "<span>... <span style='text-decoration: underline';>mehr</span></span> ";

    let textElement = this.element.querySelector('.card__content__text');
  
    if (teaser.length > MAX_CHARACTERS) {
      let truncatedTeaser = teaser.slice(0, MAX_CHARACTERS - 10) + ellipsis;
      textElement.innerHTML = truncatedTeaser;
    } else {
      textElement.innerText = teaser;
    }

    return this;
  }

  setAccessibilityTitle(title) {
    this.element.setAttribute('title', title);

    return this;
  }

  setDate(date) {
    this.element.querySelector('.card__content__date').innerText = date;

    return this;
  }

  /**
   * 
   * @param {string} hasVideo 
   * @return {ThreeColsCardElement}
   */
  setHasVideo(hasVideo) {
    this.hasVideo = hasVideo;

    if (hasVideo) {
      const button = document.createElement('button');
      button.classList.add('btn-play-static');
      button.setAttribute('type', 'button');
      button.setAttribute('tabindex', '-1')
      button.setAttribute('aria-hidden', 'true');
      this.element.querySelector(".card__content").appendChild(button);
    } else if (this.element.querySelector('button.btn-play-static')) {
      this.element.querySelector('button.btn-play-static').remove();
    }

    return this;
  }

  /**
   * 
   * @param {Node} element 
   * @return {ThreeColsCardElement}
   */
  setElement(element) {
    this.element = element.cloneNode(true);

    return this;
  }

  /**
   * 
   * @return {Node} clone of the node, to be sure, it dos not get mutated later on
   */
  getElement() {
    return this.element.cloneNode(true);
  }

  /**
   * reset the dom element (card) to have a fresh new Card
   * 
   * @return {ThreeColsCardElement}
   */
  resetElement() {
    this.setElement(this.getElement());

    this.setUrl('');
    this.setImg({
      imageAlt: "",
      imageUrl: ""
    });
    this.setHeader('');
    this.setTag('');
    this.setSubtitle('');
    this.setHasVideo(false);
    this.setDate('');

    return this;
  }

}
