// this MUST NOT be async, otherwise, we run into trouble on render
export function initPopup() {
  const popup = document.querySelector(".popup-window");

  if (popup) {
    const popupCloseButton = document.querySelector(".popup-close");
    const popupOverlay = document.querySelector("#popup-overlay");

    if (sessionStorage.getItem("popup")) {
      popup.remove();
      popupOverlay.classList.remove("active");
    } else {
      setTimeout(function () {
        popupOverlay.classList.add("active");
        popup.classList.add("active");
      }, 1000);

      if (popupCloseButton) {
        popupCloseButton.addEventListener("click", (event) => {
          event.preventDefault();
          sessionStorage.setItem("popup", "true");
          popupOverlay.classList.remove("active");
          popup.classList.remove("active");
          setTimeout(function () {
            popup.remove();
          }, 1000);
        });
      }
    }
  }
}
