import animatedScrollTo from "animated-scroll-to";

/**
 * Enables all scroll tos on page
 */
export default class ScrollTo {
  /**
   * Initiates scrollto functionality
   *
   */
  /* eslint-env es6*/
  static init({ speed } = { speed: 1 }) {
    const arrows = Array.prototype.slice.call(
      document.querySelectorAll(".scroll-to")
    );
    // const arrows = document.querySelectorAll('.scroll-to');

    arrows.forEach((node) => {
      const direction = node.dataset.direction;
      const parent = this.findParentNode({ node });
      const speedSetting = speed || 2.5;

      if (parent) {
        switch (direction) {
          case "up":
            node.addEventListener("click", () => {
              animatedScrollTo(parent.scrollTop, {
                speed: parent.scrollTop / speedSetting,
              });
            });
            break;
          default:
            node.addEventListener("click", () => {
              const scrollHeight = parent.scrollTop + parent.offsetHeight;
              animatedScrollTo(scrollHeight, {
                speed: scrollHeight / speedSetting,
              });
            });
        }
      }
    });
  }

  /**
   * Finds the parent node
   * @return {Node?}
   */
  /* eslint-env es6*/
  static findParentNode({ node }) {
    let parentNode = node.parentNode;
    let lastNode = node;

    while (
      parentNode.nodeName.toLowerCase() !== "main" &&
      parentNode.nodeName.toLowerCase() !== "#document"
    ) {
      lastNode = parentNode;
      parentNode = parentNode.parentNode;
    }

    return parentNode.nodeName.toLowerCase() === "main" ? lastNode : undefined;
  }
}
