import WaveSurfer from "wavesurfer.js";

const formatTime = (seconds) => {
  const minutes = Math.floor(seconds / 60);
  const secondsRemainder = Math.round(seconds) % 60;
  const paddedSeconds = `0${secondsRemainder}`.slice(-2);
  return `${minutes}:${paddedSeconds}`;
};

export const initAudioPlayerNew = () => {
  const newAudioPlayers = document.querySelectorAll(
    ".audio__player__container--new"
  );

  if (!newAudioPlayers) return;
  newAudioPlayers.forEach((playerWrapper) => {
    const song = playerWrapper
      .querySelector(".audio__player")
      .getAttribute("data-song");
    const track = playerWrapper.querySelector(".audio__player__wrapper__track");
    const durationElement = playerWrapper.querySelector(".audio__player__duration");
    const playButton = playerWrapper.querySelector(".btn-play-audio");
    const isDarkMode = document.body.classList.contains("contrast-on");
    const windowWidth = window.innerWidth;

    const wavesurfer = WaveSurfer.create({
      container: track,
      waveColor: isDarkMode ? "#b4b4b4" : "#0094c1",
      progressColor: "#383351",
      barWidth: 1,
      url: song,
      height: windowWidth < 768 ? 30 : 60,
    });

    playButton.addEventListener("click", () => {
      wavesurfer.playPause();
      playButton.classList.toggle("pause");
    });

    wavesurfer.on(
      "decode",
      (duration) => (durationElement.textContent = formatTime(duration))
    );

    wavesurfer.on("interaction", () => {
      wavesurfer.play();

      if (wavesurfer.isPlaying()) {
        playButton.classList.add("pause");
      } else {
        playButton.classList.remove("pause");
      }
    });
  });
};

