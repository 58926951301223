import { ScrollTrigger } from "gsap/ScrollTrigger"; // quickFix
import ThreeColsCardElement from "./threeColsCardElement";

/**
 * this handles the creation of the filters, the filtering of the cards and the rerendering/rebuilding of the card elements
 *
 * @return {void}
 */
export async function threeCols() {
  let baseCard;
  try {
    baseCard = generateBaseCard();
  } catch (error) {
    console.info(error);
    return;
  }

  const lastCard = copyLastCardElement();

  window.threeColCardCeFilter = {
    categories: [],
    tags: [],
    maxTeaserCount: [],
  };

  // Added to handle better the case when there is no card
  if(!window.threeColCardCe) return;

  Object.keys(window.threeColCardCe).forEach((ceUid) => {
    let {
      cardJson,
      allCategoriesJson,
      allTagsJson,
      settingsJson,
      amount_first_view,
      amount_first_view_mobile,
      blue_background,
    } = window.threeColCardCe[ceUid];
    cardJson = cardJson
      .map((card) => {
        if (!card.categories) card.categories = [18];
        card.categories = card.categories.map((category) => category.uid);
        return card;
      })
      .map((card) => {
        if (!card.tags) card.tags = [16];
        card.tags = card.tags.map((tag) => tag.uid);
        return card;
      })
      .sort((a, b) => b.date - a.date);

    if (!settingsJson) {
      window.threeColCardCe[ceUid].settingsJson = {
        showFilter: 0,
        initialCardCount: Number(amount_first_view),
        initialCardCountMobile: Number(amount_first_view_mobile),
      };
      settingsJson = window.threeColCardCe[ceUid].settingsJson;
    }
    if (settingsJson.showFilter === "1") {
      initFilters(
        allCategoriesJson,
        allTagsJson,
        cardJson,
        ceUid,
        baseCard,
        lastCard
      );
    }
    processSettings(settingsJson, ceUid);

    rerenderCards(ceUid, cardJson, baseCard, lastCard);

    initLoadMoreButton(ceUid, cardJson, baseCard, lastCard);

    initBackgroundColor(ceUid, blue_background);

    changeSortButton(settingsJson.showFilter);
  });
}
/**
 *
 * @param {*} settings
 * @param {*} ceUid
 * @return {void}
 */
function processSettings(settings, ceUid) {
  let initialCardCount;

  const isMobile = window.matchMedia("(max-width: 1250px)").matches;

  if (isMobile) {
    initialCardCount = settings.initialCardCountMobile ?? 3;
  } else {
    initialCardCount = settings.initialCardCount;
  }

  window.threeColCardCeFilter.maxTeaserCount.push({
    ceUid,
    count: Number(initialCardCount),
  });
}

/**
 *
 * @return {Node|string}
 * @return {void}
 */
function copyLastCardElement() {
  const card = document.querySelector(".page-teaser-card.last-card");
  if (!card) return "";

  return card.cloneNode(true);
}

/**
 *
 * @return {ThreeColsCardElement} new card elements based on the first card, found in the Dom
 */
function generateBaseCard() {
  const firstCard = document.querySelector(".page-teaser-card:first-child");

  if (!firstCard) throw new Error("no card element found");

  const firstCardClone = firstCard.cloneNode(true);
  firstCardClone.classList.remove("video-card");

  const baseCardElement = new ThreeColsCardElement(firstCardClone);
  // console.log(firstCardClone)
  return baseCardElement;
}

/**
 *
 * @param {Object} allCategoriesJson
 * @param {Object} allTagsJson
 * @param {Object} cardJson
 * @param {int} ceUid
 * @param {Node} baseCard
 * @param {Node} lastCard
 * @return {void}
 */
function initFilters(
  allCategoriesJson,
  allTagsJson,
  cardJson,
  ceUid,
  baseCard,
  lastCard
) {
  const filterContainer = document.querySelector(`#card-filter-${ceUid}`);
  const filterSwitch = document.createElement("button");
  const resetFilterButton = document.createElement("button");
  if (filterContainer) {
    filterSwitch.addEventListener("click", (event) => {
      const classNameOpen = "card-filter--open";
      const classNameClose = "card-filter--close";

      if (
        event.target.parentNode.parentNode.classList.contains(classNameOpen)
      ) {
        event.target.parentNode.parentNode.classList.remove(classNameOpen);
        event.target.parentNode.parentNode.classList.add(classNameClose);
        filterSwitch.innerHTML = "Filtern";
        changePillTabIndex(false);
      } else {
        event.target.parentNode.parentNode.classList.remove(classNameClose);
        event.target.parentNode.parentNode.classList.add(classNameOpen);
        filterSwitch.innerHTML = "Schließen";
        changePillTabIndex(true);
      }
    });

    filterSwitch.innerHTML = "Filtern";
    filterSwitch.classList.add("pill", "card-filter--toggle");
    filterSwitch.classList.add("button-white-transparent");

    resetFilterButton.classList.add("pill", "pill--outlined", "pill-tabindex");
    resetFilterButton.tabIndex = "-1";
    resetFilterButton.innerHTML = "Alle Artikel";
    resetFilterButton.addEventListener("click", () => {
      resetFilter(ceUid, cardJson, baseCard, lastCard);
    });

    filterContainer.appendChild(filterSwitch);
    filterContainer.appendChild(resetFilterButton);
  }

  FillCategoryFilters(
    filterContainer,
    allCategoriesJson,
    ceUid,
    baseCard,
    lastCard
  );
  FillTagsFilters(filterContainer, allTagsJson, ceUid, baseCard, lastCard);
}

/**
 *
 * @param {Object} filterContainer
 * @param {Object} categoryList
 * @param {int} ceUid
 * @param {Node} baseCard
 * @param {Node} lastCard
 * @return {void}
 */
function FillCategoryFilters(
  filterContainer,
  categoryList,
  ceUid,
  baseCard,
  lastCard
) {
  FillFilter(
    filterContainer,
    categoryList,
    "categories",
    ceUid,
    baseCard,
    lastCard
  );
}

/**
 *
 * @param {Object} filterContainer
 * @param {Object} tagsList
 * @param {int} ceUid
 * @param {Node} baseCard
 * @param {Node} lastCard
 * @return {void}
 */
function FillTagsFilters(filterContainer, tagsList, ceUid, baseCard, lastCard) {
  FillFilter(filterContainer, tagsList, "tags", ceUid, baseCard, lastCard);
}

/**
 *
 * @param {Node} filterContainer
 * @param {Object} elementList
 * @param {string} type
 * @param {int} ceUid
 * @param {Node} baseCard
 * @param {Node} lastCard
 * @return {void}
 */
function FillFilter(
  filterContainer,
  elementList,
  type,
  ceUid,
  baseCard,
  lastCard
) {
  // it makes no sense, to have only one Tag or Category filter
  if (elementList.length === 1) {
    return;
  }

  elementList.forEach((item) => {
    const filter = document.createElement("button");
    filter.setAttribute("aria-label", `Filter ${item.title} deaktiviert`);
    filter.addEventListener("click", (event) => {
      toggleFilter(event.target, type, ceUid, item.uid, baseCard, lastCard, item.title)
    }
    );
    filter.classList.add("pill");
    filter.classList.add("pill-tabindex");
    filter.tabIndex = "-1";
    filter.value = item.uid;
    filter.innerHTML = item.title;
    if (filterContainer) filterContainer.appendChild(filter);
  });
}

/**
 *
 * @param {Node} target
 * @param {string} type
 * @param {int} ceUid
 * @param {int} id
 * @param {Node} baseCard
 * @param {Node} lastCard
 * @return {void}
 */
function toggleFilter(target, type, ceUid, id, baseCard, lastCard, title) {
  const index = window.threeColCardCeFilter[type].findIndex(
    (item) => item.id === id
  );

  if (index === -1) {
    window.threeColCardCeFilter[type].push({ id, ceUid });
    target.classList.add("active");
    target.setAttribute("aria-label", `Filter ${title} aktiv`);
  } else {
    window.threeColCardCeFilter[type].splice(index, 1);
    target.classList.remove("active");
    target.setAttribute("aria-label", `Filter ${title} deaktiviert`);
  }
  rerenderCards(
    ceUid,
    window.threeColCardCe[ceUid].cardJson,
    baseCard,
    lastCard
  );
}

/**
 *
 * @param {int} ceUid
 * @param {Object} cardJson
 * @param {Node} baseCard
 * @param {Node} lastCard
 * @return {void}
 */
function rerenderCards(ceUid, cardJson, baseCard, lastCard) {
  const containerElement = document.querySelector(`#card-markup-${ceUid}`);
  const loadMoreButton = document.querySelector(`#load-more-${ceUid}`);

  containerElement.innerHTML = "";
  containerElement.setAttribute("aria-live", "polite");
  let cardList = getFilteredCards(ceUid, cardJson);
  let maxTeaserCount = window.threeColCardCeFilter.maxTeaserCount.find(
    (s) => s.ceUid === ceUid
  ).count;

  if (typeof lastCard === "object") {
    maxTeaserCount--;
  }

  if ([...cardList].length === 0) {
    renderNoResultsCard(containerElement);
  }

  [...cardList]
    .splice(0, maxTeaserCount)
    .forEach((card) =>
      renderCard(ceUid, containerElement, card, baseCard.resetElement())
    );

  if (typeof lastCard === "object") {
    containerElement.appendChild(lastCard);
  }

  if (loadMoreButton) {
    if (cardList.length <= maxTeaserCount) {
      loadMoreButton.classList.add("hide");
    } else {
      loadMoreButton.classList.remove("hide");
    }
  }

  ScrollTrigger.refresh(); // quickFix
}

function renderNoResultsCard(containerElement) {
  const noResultsCard = document.createElement("div");
  noResultsCard.className = "noResultsCard";

  const headline = document.createElement("span");
  headline.className = "headline";
  headline.innerText =
    "Für diese Anfrage konnten keine Artikel gefunden werden.";

  noResultsCard.appendChild(headline);

  containerElement.appendChild(noResultsCard);
}

/**
 *
 * @param {int} ceUid 
 * @param {Node} containerElement
 * @param {Object} card
 * @param {Node} baseCard
 * @return {void}
 */
function renderCard(ceUid, containerElement, card, baseCard) {
  const showFirstTag =
    "1" === window.threeColCardCe[ceUid].settingsJson.showFilter;
  const hasTags = card.tags && !!card.tags[0];
  baseCard.setImg(card.teaserImage);
  baseCard.setHeader(card.title);
  baseCard.setUrl(card.link);
  baseCard.setHasVideo(card.videoCard);
 
  if (card.videoCard) {
    baseCard.setAccessibilityTitle(`Video und Artikel zum Thema ${card.title} anzeigen`);
  } else {
    baseCard.setAccessibilityTitle(`Artikel zum Thema ${card.title} anzeigen`);
  }
  
  const date = new Date(card.date * 1000);
  const day = date.getDate();
  const month = date.getMonth() + 1; // Months are zero-indexed in JavaScript
  const year = date.getFullYear();

  // Pad day and month with leading zeros if they are single-digit
  const formattedDay = day < 10 ? "0" + day : "" + day;
  const formattedMonth = month < 10 ? "0" + month : "" + month;

  const formattedDate = `${formattedDay}.${formattedMonth}.${year}`;
  baseCard.setDate(formattedDate);

  if (showFirstTag && hasTags) baseCard.setTag(getTagById(card.tags[0], ceUid));

  baseCard.setSubtitle(card.subtitle);

  containerElement.appendChild(baseCard.getElement());
}

/**
 *
 * @param {int} tagId
 * @param {int} ceUid
 * @return {string}
 */
function getTagById(tagId, ceUid) {
  const tag = window.threeColCardCe[ceUid].allTagsJson.find(
    (t) => t.uid === tagId
  );
  return tag.title;
}

/**
 *
 * @param {int} ceUid
 * @param {Object} cardJson
 * @return {Object[]}
 */
function getFilteredCards(ceUid, cardJson) {
  const catFilter = window.threeColCardCeFilter.categories
    .filter((cat) => cat.ceUid === ceUid)
    .map((cat) => cat.id);

  const tagFilter = window.threeColCardCeFilter.tags
    .filter((tag) => tag.ceUid === ceUid)
    .map((tag) => tag.id);

  let cardList = [...cardJson];
  if (catFilter.length > 0) {
    catFilter.forEach((categoryId) => {
      cardList = cardList.filter((card) => {
        const found = card.categories.find((id) => id === categoryId);
        return typeof found !== "undefined";
      });
    });
  }
  if (tagFilter.length > 0) {
    tagFilter.forEach((tagId) => {
      cardList = cardList.filter(
        (card) => !!card.tags.find((id) => id === tagId)
      );
    });
  }

  return cardList;
}

/**
 *
 * @param {int} ceUid
 * @param {Object} cardJson
 * @param {Node} baseCard
 * @param {Node} lastCard
 * @return {void}
 */
function initLoadMoreButton(ceUid, cardJson, baseCard, lastCard) {
  const loadMoreButton = document.querySelector(
    `#card-load-more-button-${ceUid}`
  );
  const loadNewTeasers = 3;
  if (!loadMoreButton) return;

  const maxTeaserCount = window.threeColCardCeFilter.maxTeaserCount.find(
    (s) => s.ceUid === ceUid
  );

  loadMoreButton.querySelector(".label").innerHTML = "Mehr laden";
  loadMoreButton.addEventListener("click", (event) => {
    event.preventDefault();
    event.target.classList.add("is-loading");

    maxTeaserCount.count = loadNewTeasers + maxTeaserCount.count;

    rerenderCards(ceUid, cardJson, baseCard, lastCard);
    setTabindexFocus(maxTeaserCount.count);

    event.target.classList.remove("is-loading");
  });
}

/**
 *
 * @param {int} ceUid
 * @param {Object} cardJson
 * @param {Node} baseCard
 * @param {Node} lastCard
 * @return {void}
 */
function resetFilter(ceUid, cardJson, baseCard, lastCard) {
  window.threeColCardCeFilter.categories =
    window.threeColCardCeFilter.categories.filter(
      (filter) => filter.ceUid !== ceUid
    );

  window.threeColCardCeFilter.tags = window.threeColCardCeFilter.tags.filter(
    (filter) => filter.ceUid !== ceUid
  );

  document
    .querySelector(`#card-filter-${ceUid}`)
    .querySelectorAll(".pill.active")
    .forEach((event) => event.classList.remove("active"));

  rerenderCards(ceUid, cardJson, baseCard, lastCard);
}

function initBackgroundColor(ceUid, background) {
  const setBlue = Number(background) === 1;
  if (setBlue) {
    const sectionElement = document.querySelector(`#col3-fillable-${ceUid}`);
    if (sectionElement) {
      const parent = sectionElement.parentElement;
      if (parent.classList.contains("three-col-card-box")) {
        parent.classList.add("three-col-card-box--blue");
      }
    }
  }
}

function setTabindexFocus(count) {
  const newTeaserList = document.querySelectorAll(".page-teaser-card");
  const lastTeaser = count - 3;
  if (newTeaserList[lastTeaser]) newTeaserList[lastTeaser].focus();
}

function changeSortButton(showFilter) {
  const anchor = document.querySelector(".change-display-js");

  if (!anchor) return;

  if (showFilter !== "1") {
    anchor.style.display = "none";
    return;
  }
  anchor.addEventListener("click", (element) => {
    element.preventDefault();
    element.stopPropagation();
    if (!element.target.classList.contains("sort-beneath")) {
      element.target.classList.add("sort-beneath");
      const pillSection = document.querySelector(".card-filter");

      pillSection.classList.add("pill-block");
    } else {
      element.target.classList.remove("sort-beneath");
      const pillSection = document.querySelector(".card-filter");

      pillSection.classList.remove("pill-block");
    }
  });
}

function changePillTabIndex(state) {
  const pills = document.querySelectorAll(".pill-tabindex");

  if (pills.length === 0) return;

  pills.forEach((pill) => {
    if (state) {
      pill.tabIndex = "0";
    } else {
      pill.tabIndex = "-1";
    }
  });
}

export async function initPill() {
  if (document.querySelectorAll(".card-filter").length > 0) {
    const wrappers = document.querySelectorAll(".pill-wrapper");
    wrappers.forEach((wrapper) => {
      unwrap(wrapper);
    });
    const cards = document.querySelector(".card-filter");
    const pills = document.querySelectorAll(".pill");
    // let windowWidth = window.innerWidth;

    // console.log("hol");
    let pillWrapperList = {};

    pills.forEach((pill) => {
      let pillTop = pill.getBoundingClientRect().top;

      if (!pillWrapperList[pillTop]) {
        pillWrapperList[pillTop] = [];
      }
      pillWrapperList[pillTop].push(pill);
    });

    Object.values(pillWrapperList).forEach((currentLine) => {
      // console.log(currentLine);
      let div = document.createElement("div");
      currentLine.forEach((pill) => {
        div.appendChild(pill);
        div.classList.add("pill-wrapper");
      });
      cards.appendChild(div);
    });
  }
}

function unwrap(wrapper) {
  // place childNodes in document fragment
  var documentFrag = document.createDocumentFragment();
  while (wrapper.firstChild) {
    var child = wrapper.removeChild(wrapper.firstChild);
    documentFrag.appendChild(child);
  }

  // replace wrapper with document fragment
  wrapper.parentNode.replaceChild(documentFrag, wrapper);
}
