import Splide from "@splidejs/splide";

export const initHeroSlider = async () => {
  const heroSliders = document.querySelectorAll(".hero-slider-wrapper");
  const windowWidth = window.innerWidth;
  const isBigDesktop = windowWidth > 1365;

  if (heroSliders) {
    const sliderOptions = {
      type: isBigDesktop ? "slide" : "fade",
      rewind: true,
      arrows: false,
      autoplay: true,
      interval: 5000,
      perPage: 1,
      perMove: 1,
      lazyLoad: true,
      pagination: false,
      pauseOnHover: false,
      pauseOnFocus: false,
      drag: isBigDesktop ? false : true,
    };

    const initSlider = (hero) => {
      const navigationItems = hero.querySelectorAll(".navigation");
      let index = 0;
      const numberOfElements = navigationItems.length;
      const sliderContainer = hero.querySelector(".hero-slider");
      const navigationItemsDots = hero.querySelectorAll(
        ".splide__pagination__page"
      );
      const hiddenRadioButtonList = hero.querySelectorAll(".slide-toggle");

      const numberOfSlidesCounter = hero.querySelector(
        ".hero__nav-controls-counter--end"
      );
      numberOfSlidesCounter.innerText = "0" + navigationItems.length;

      navigationItems[index].classList.add("current");
      navigationItemsDots[index].classList.add("is-active");

      const splideSlider = new Splide(sliderContainer, sliderOptions);

      const swipeHandler = handleSwipe(
        sliderContainer,
        numberOfElements,
        splideSlider
      );
      handleSwipe(sliderContainer, index, numberOfElements, splideSlider);

      const autoplayButton = splideSlider.root.querySelector(
        ".hero-autoplay-button"
      );

      // Handle autoplay button behaviour
      splideSlider.on("autoplay:play", function () {
        autoplayButton.setAttribute("aria-label", "Autoplay pausieren");
        autoplayButton.setAttribute("title", "Autoplay pausieren");
        autoplayButton.textContent = "";
        autoplayButton.classList.add("pause");
        autoplayButton.classList.remove("play");
      });

      splideSlider.on("autoplay:pause", function () {
        autoplayButton.setAttribute("aria-label", "Autoplay aktivieren");
        autoplayButton.setAttribute("title", "Autoplay aktivieren");
        autoplayButton.textContent = "▶";
        autoplayButton.classList.remove("pause");
        autoplayButton.classList.add("play");
      });

      autoplayButton.addEventListener("click", function () {
        var Autoplay = splideSlider.Components.Autoplay;

        if (Autoplay.isPaused()) {
          Autoplay.play();
        } else {
          Autoplay.pause();
        }
      });

      // Adding visible percentage to navigation
      splideSlider.on("autoplay:playing", (rate) => {
        navigationItems.forEach(
          (control) => (control.querySelector(".rate").style.width = 0)
        );
        navigationItems[index].querySelector(".rate").style.width = `${
          rate * 100
        }%`;
      });

      // to disable the CSS fallback of the hero-slider, we just need to remove the class, that is also used as selector in the CSS
      hiddenRadioButtonList.forEach((radioButton) =>
        radioButton.classList.remove("slide-toggle")
      );

      swipeHandler(index);

      splideSlider.on("mounted", () => {
        navigationItemsDots.forEach((_control, index) => {
          const text = navigationItems[index].querySelector("p").innerText;
          navigationItemsDots[index].setAttribute(
            "aria-label",
            "Slider zum Thema " + text
          );
        });
      });

      // Reset navigation on slide change
      splideSlider.on("moved", (current) => {
        // console.log("This is the current index after moved", current);
        index = current;
        swipeHandler(index);

        const text = navigationItems[index].querySelector("p").innerText;
        navigationItemsDots[index].setAttribute(
          "aria-label",
          "Bild zum Thema " + text
        );

        setTabindex();

        // Change the labels
        navigationItems.forEach((control) => {
          control.classList.remove("current");
          control.removeAttribute("aria-current");
        });
        navigationItems[index].classList.add("current");
        navigationItems[index].setAttribute("aria-current", true);

        // Change the dots and handle arias for active
        navigationItemsDots.forEach((control) => {
          control.classList.remove("is-active");
          control.removeAttribute("aria-current");
        });
        navigationItemsDots[index].classList.add("is-active");
        navigationItemsDots[index].setAttribute("aria-current", true);
      });

      // Adding change event to navigation
      navigationItems.forEach((navigation, navIndex) => {
        navigation.addEventListener("click", () => {
          splideSlider.go(navIndex);
        });
      });

      // Adding change event to navigation dots
      navigationItemsDots.forEach((navigation, navIndex) => {
        navigation.addEventListener("click", () => {
          splideSlider.go(navIndex);
        });
      });

      splideSlider.mount();
      setTabindex();
    };

    heroSliders.forEach(initSlider);
  }
};

function setTabindex() {
  const buttons = document.querySelectorAll(".splide__slide .btn");
  buttons.forEach((button) => {
    button.tabIndex = "-1";
  });
  const activeSlide = document.querySelector(".splide__slide.is-active");
  const activeSlideButton = activeSlide.querySelector(".btn");
  if (activeSlideButton) activeSlideButton.tabIndex = "0";
}

function handleSwipe(sliderContainer, numberOfElements, splideSlider) {
  return function (currentIndex) {
    // <-- Higher-order function taking the current index
    // Reset any existing event listeners to avoid duplication
    // sliderContainer.removeEventListener("pointerleave");

    let pointerStartX = 0;
    let pointerStartY = 0;
    let pointerEndX = 0;
    let pointerEndY = 0;

    // Handle pointerdown event
    sliderContainer.addEventListener("pointerdown", (event) => {
      pointerStartX = event.clientX;
      pointerStartY = event.clientY;
    });

    // Handle pointermove event
    sliderContainer.addEventListener("pointermove", (event) => {
      pointerEndX = event.clientX;
      pointerEndY = event.clientY;
    });

    // Handle pointerleave event
    sliderContainer.addEventListener("pointerleave", (event) => {
      const deltaY = Math.abs(pointerEndY - pointerStartY);

      if (deltaY < 50 && event.pointerType === "touch") {
        // console.log("This is the event", event);
        // console.log("This is the pointerEndX", pointerEndX);
        // console.log("This is the pointerStartX", pointerStartX);

        // console.log("This is the offsetX", event.offsetX);
        // console.log("This is the offsetY", event.offsetY);

        // console.log("OKAY");
        if (pointerEndX < pointerStartX) {
          // Swipe Left (Next Slide)
          // console.log(currentIndex);
          // console.log("we should go to the next slide");

          splideSlider.go(">");

          return;
        } else if (pointerEndX > pointerStartX) {
          // console.log(currentIndex);
          // console.log("we should go to the previous slide");
          // Swipe Right (Previous Slide)

          splideSlider.go("<");

          return;
        }

        // Reset pointer coordinates
        pointerStartX = 0;
        pointerEndX = 0;
      }
    });
  };
}
