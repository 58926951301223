export const initModal = () => {
    const modal = document.querySelector("#quoteModal");
    let count = 0;

    function Sleep(milliseconds) {
        return new Promise(resolve => setTimeout(resolve, milliseconds));
    }

    async function start() {
        await Sleep(10000);
        getBigQuote();
        await Sleep(30000);
        closeBigQuote();
        start();
    }

    function getBigQuote() {
        const url = window.location.origin + '/?type=1337';
        const random = Math.floor(Math.random() * 12);
        const modalContent = document.querySelector("#quoteModalContent");

        fetch(url)
            .then((resp) => resp.json())
            .then(function(data) {
                data.messages.forEach(async(message) => {
                    console.log(message);
                });
                const quotes = data.messages;
                const message = quotes[random];
                let bubble = document.createElement('article');
                bubble.innerHTML = '</span><div class="article-text">... ' + message.message + '</div><div class="article-author"><blockquote class="author">' + message.writer + '</blockquote></div>';
                modalContent.innerHTML ='';
                modalContent.prepend(bubble);
                modal.style.opacity = 1;
            })
            .catch(function(error) {
                console.log(error);
            });
    }

    function closeBigQuote() {
        modal.style.opacity = 0;
    }

    if (modal) {
        start();
    }
};
