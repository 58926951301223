import {$$} from "./utils";

export const initReadMore = () => {
    const readMore = $$(".read-more");
    let minElementHeight = 100; //px

    if (readMore.length > 0) {
        readMore.forEach((element) => {
            if (element.offsetHeight > minElementHeight) {
                let wrapper = document.createElement('div');
                let button = document.createElement('a');
                button.classList.add('read-more-button');
                button.setAttribute('href', 'javascript:;');

                button.text = document.body.dataset.readmorebutton;

                wrapper.classList.add('read-more-wrapper');
                element.parentNode.insertBefore(wrapper, element);
                wrapper.appendChild(element);
                wrapper.appendChild(button);

                button.addEventListener("click", () => {
                    wrapper.classList.toggle('open');
                });

                wrapper.addEventListener("click", (event) => {
                    if (wrapper.classList.contains('open') && !(event.target instanceof HTMLAnchorElement)) {
                        wrapper.classList.remove('open');
                    }
                });
            }
        });
    }
};
