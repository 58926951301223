import { $$ } from './utils';

export async function initLoadMore() {
  // const loadMoreButtons = $$('button[type="submit"]');
  const loadMoreButtons = $$('.btn-loading');
  if (!loadMoreButtons) return;

  // const container = $(".postcontainer");
  // const pagination = $(".blogpagination");
  // const blogsToLoad = [];

  /*
    // Paginationslinks auslesen
    pagination.querySelector('li:not(.active):not(.last)').forEach(function() {
        blogsToLoad.push(this.querySelector('a.blogpagination__link').getAttribute('href'));
    });
*/

  // Button Event & Ajax-Request
  loadMoreButtons.forEach((loadMoreButton) => {
    loadMoreButton.addEventListener('click', (event) => {
      if (loadMoreButton.classList.contains('is-loading')) {
        return;
      }
      loadMoreButton.classList.add('is-loading');
      setTimeout(function() {
        loadMoreButton.classList.remove('is-loading');
      }, 3000);
    });
  });
}

// url = blogsToLoad[0] + '/load.more' + '?no_cache=1';

// fetch( url )
//     .then(data => {

//         var temp = $(data);

//         var content = temp.innerHTML;

//         //container.find('.grid').append( content );
//         var $items = $( content );

//         // append items to grid
//         container.appendChild( $items );

//         loadMoreButton.removeClass('loading');

//         if(blogsToLoad.length == 0) {
//             loadMoreButton.parentNode.removeChild( loadMoreButton );
//         }

//   }).catch(error => {
//     // Handle error
//     console.log('fetch failed');
//   });
