/**
 * loops all contact forms and executes the initialisation process
 */
export async function initContactForm() {
  const contactForm = document.querySelectorAll('form.contactform');

  contactForm.forEach(initFormValidationHandling);
}

/**
 * gets all form inputs, filters for only inputs, that can be changed by the user
 * and initializes the validation and submit button handling
 * 
 * @param {Array<Node>} contactForm 
 */
function initFormValidationHandling(contactForm) {
  const submitButton = contactForm.querySelector('[type="submit"]');
  const inputList = [...contactForm.querySelectorAll('input, textarea')];
  const filteredInputList = filterInputList(inputList);

  // pre init the submit button, so the inputs get validated, if the page get reloaded
  enableSubmitButtonOnValidInputs(submitButton, filteredInputList);

  // init on change event for all inputs
  filteredInputList.forEach(input => {
    input.addEventListener('input', () => {
      enableSubmitButtonOnValidInputs(submitButton, filteredInputList);
    });
  });
}

/**
 * does the actual filtration for user changeable inputs
 * 
 * @param {Array<Node>} inputList 
 * @return {boolean}
 */
function filterInputList(inputList) {
  const filteredInputList = inputList.filter(input => {
    const tabIndex = Number(input.getAttribute('tabindex'));
    const isHiddenInput = input.type === 'hidden';

    return (tabIndex >= 0) && !isHiddenInput;
  });
  return filteredInputList;
}

/**
 * enable the submit button, if all inputs are validated successfully
 * otherwise disable the submit button
 * 
 * @param {Node} submitButton 
 * @param {Array<Node>} filteredInputList 
 */
function enableSubmitButtonOnValidInputs(submitButton, filteredInputList) {
  const enableButton = validateAllInputs(filteredInputList);
  enableSubmitButton(submitButton, enableButton);
}

/**
 * validates by checking, if the checkbox is checked and the inputs are filled
 * 
 * @param {Array<Node>} filteredInputList 
 * @return {boolean}
 */
function validateAllInputs(filteredInputList) {
  const failedChecks = filteredInputList.filter(input => input.required)
    .filter(input => {
      const isValidCheckbox = input.type === 'checkbox' && !input.checked;
      const isValidText = input.type !== 'checkbox' && input.value.length < 3;

      return isValidCheckbox || isValidText;
    });

  const enableButton = failedChecks.length === 0;
  return enableButton;
}

/**
 * enables / disables the submit button
 * 
 * @param {Node} button 
 * @param {boolean} enable 
 */
function enableSubmitButton(button, enable = true) {
  if (enable === true) {
    button.classList.remove('disabled');
    button.removeAttribute('disabled');
  } else {
    button.classList.add('disabled');
    button.setAttribute('disabled', 'disabled');
  }
}