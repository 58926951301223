import { isCookieBannerOpen } from "./cookie";

const INSTAGRAM_ID = "Instagram-id";
const TIKTOK_ID = "TikTok-id";

const INSTAGRAM_SCRIPT_SRC = "//www.instagram.com/embed.js";
const TIKTOK_SCRIPT_SRC = "https://www.tiktok.com/embed.js";

const MIN_TIME_TO_AVOID_LCS = 2500;

/**
 * Initialize the social embeds logic
 * @return {Promise<void>}
 * @example
 * import { initSocialEmbed } from "./socialEmbed";
 * initSocialEmbed();
 */
export async function initSocialEmbed() {
  const cookieBanner = document.querySelector(".cookie-banner");
  const cookieButton = document.querySelector(".cookie-button");
  const cookieOptions = document.querySelector(".cookie-options");
  const embedElements = document.querySelectorAll(".social-embed");

  if (embedElements) {
    embedElements.forEach((embed) => {
      /** @type {HTMLElement | null} */
      const embedContainer = embed.querySelector(".social-embed__platform");
      if (!embedContainer) return;

      const embedType = embedContainer.dataset.platform;
      const embedModal = embed.querySelector(".social-embed__modal");
      const embedPlayButton = embed.querySelector(".social-embed__button-play");
      const embedOpenCookieButton = embed.querySelector(
        ".social-embed__modal-cookie"
      );
      const embedCloseModalButton = embed.querySelector(
        ".social-embed__modal-close"
      );

      embedCloseModalButton?.addEventListener("click", () => {
        embedModal?.classList.remove("active");
        setVideoModalButtonsTabIndex(embedModal, false);
      });

      embedPlayButton?.addEventListener("click", () => {
        if (embedType === "instagram") {
          // HANDLING INSTAGRAM
          let isInstagramAccepted = localStorage.getItem(INSTAGRAM_ID);
          if (!isInstagramAccepted) {
            embedModal?.classList.add("active");
            setVideoModalButtonsTabIndex(embedModal, true);
          } else {
            embedContainer.innerHTML = embedContainer.dataset.src;

            if (window.instgrm) {
              window.instgrm.Embeds.process();
            } else {
              const script = document.createElement("script");
              script.src = INSTAGRAM_SCRIPT_SRC;
              script.async = true;
              script.addEventListener("load", () =>
                window.instgrm.Embeds.process()
              );
              document.body.appendChild(script);
            }
          }
        } else if (embedType === "tiktok") {
          // HANDLING TIKTOK
          let isTikTokAccepted = localStorage.getItem(TIKTOK_ID);
          if (!isTikTokAccepted) {
            embedModal?.classList.add("active");
            setVideoModalButtonsTabIndex(embedModal, true);
          } else {
      
            // Remove the existing TikTok script to avoid duplicates
            const existingTikTokScript = document.querySelector(
              `script[src="${TIKTOK_SCRIPT_SRC}"]`
            );
            if (existingTikTokScript) {
              existingTikTokScript.remove();
            }

            // Insert the TikTok embed content
            const temporalContainer = document.createElement("div");
            temporalContainer.innerHTML = embedContainer.dataset.src;
            embedContainer.innerHTML = `
              <div>
                <div class="social-embed__overlay">
                  <div class="loader"></div>
                </div>
                <div class="social-embed__instawrap" style="display: none;">
                  ${temporalContainer.innerHTML}
                </div>
              </div>
          
            `;
            // Add a new TikTok script
            const tikTokScript = document.createElement("script");
            tikTokScript.src = TIKTOK_SCRIPT_SRC;
            tikTokScript.async = true;
            document.body.appendChild(tikTokScript);

            setTimeout(() => {
              const instaWrap = embedContainer.querySelector(
                ".social-embed__instawrap"
              );
              instaWrap.style.display = "block";
              const loaderOverlay = embedContainer.querySelector(
                ".social-embed__overlay"
              );
              loaderOverlay.style.display = "none";
            }, MIN_TIME_TO_AVOID_LCS);
          }
        }
      });

      embedOpenCookieButton?.addEventListener("click", () => {
        embedModal?.classList.remove("active");
        setVideoModalButtonsTabIndex(embedModal, false);
        embedModal?.setAttribute("aria-hidden", "true");
        cookieBanner?.classList.add("active");
        cookieButton?.classList.remove("active");

        isCookieBannerOpen();

        let inputElement;
        if (embedType === "instagram") {
          inputElement = cookieOptions?.querySelector(
            `input[id="${INSTAGRAM_ID}"]`
          );
        } else if (embedType === "tiktok") {
          inputElement = cookieOptions?.querySelector(
            `input[id="${TIKTOK_ID}"]`
          );
        }
        inputElement?.focus();
      });
    });
  }
}

/**
 * Set the tabindex of the buttons inside the modal
 * @param {HTMLElement} element
 * @param {boolean} bool
 * @return {void}
 */
function setVideoModalButtonsTabIndex(element, bool) {
  const buttons = element.querySelectorAll("button");
  buttons.forEach((button) => {
    if (bool) button.tabIndex = "0";
    else button.tabIndex = "-1";
  });
}

/**
 * Add the Instagram and TikTok scripts if the user has accepted the cookies
 * We did not use this function yet, but maybe for the future
 */
function addInitialScripts() {
  if (localStorage.getItem(INSTAGRAM_ID)) {
    const script = document.createElement("script");
    script.src = INSTAGRAM_SCRIPT_SRC;
    script.async = true;
    document.body.appendChild(script);
  }

  if (localStorage.getItem(TIKTOK_ID)) {
    const script = document.createElement("script");
    script.src = TIKTOK_SCRIPT_SRC;
    script.async = true;
    document.body.appendChild(script);
  }
}
