const cookieButton = document.querySelector(".cookie-button");
const cookieBanner = document.querySelector(".cookie-banner");
const cookieCancelButton = document.querySelector(".cookie-cancel");
const cookieAcceptButton = document.querySelector(".cookie-accept");
const cookieParameters = document.querySelectorAll(".cookie-parameter");
const cookieOverlay = document.querySelector("#cookie-overlay");
const cookieLinks = document.querySelectorAll(".cookie-layer-menu a");
const openButton = document.querySelector("[data-open-details-cookies]");
const entranceTime = 1000;

export async function initCookie() {
  // Handle analytics from Matomo
  const addMatomoAnalytics = () => {

    var _paq = (window._paq = window._paq || []);
    /* tracker methods like "setCustomDimension" should be called before "trackPageView" */
    _paq.push(["disableCookies"]);
    _paq.push(['trackPageView']);
    _paq.push(['enableLinkTracking']);
    (function() {
      var u="//matomo.das-ist-thueringen.de/";
      _paq.push(['setTrackerUrl', u+'matomo.php']);
      _paq.push(['setSiteId', '1']);
      var d=document, g=d.createElement('script'), s=d.querySelectorAll('script')[0];
      g.async=true; g.src=u+'matomo.js'; s.parentNode.insertBefore(g,s);
    })();
  };

  if (1)  //always
  {
    addMatomoAnalytics();
  }

  const inputDefaultId = document
    .querySelector(".cookie-parameter__input-default")
    .getAttribute("id");

  if (cookieBanner) {
    // Verify that only one overlay is possible at the same time: either popup or cookie one
    if (localStorage.getItem(inputDefaultId) === "true") {
      cookieButton.classList.add("active");
    } else if (
      sessionStorage.getItem("popup") &&
      !localStorage.getItem(inputDefaultId)
    ) {
      setTimeout(function () {
        cookieOverlay.classList.add("active");
        cookieBanner.classList.add("active");
        isCookieBannerOpen();
      }, entranceTime);
    } else {
      setTimeout(function () {
        cookieBanner.classList.add("active");
        isCookieBannerOpen();
      }, entranceTime);
    }

    cookieButton.addEventListener("click", () => {
      cookieBanner.classList.add("active");
      isCookieBannerOpen();
      cookieButton.classList.remove("active");
      cookieOverlay.classList.add("active");
    });

    function handleCookieBanner(isAccept) {
      cookieBanner.classList.remove("active");
      isCookieBannerOpen();
      cookieOverlay.classList.remove("active");
      cookieButton.classList.add("active");

      if (isAccept) {
        localStorage.setItem(inputDefaultId, "true");

        cookieParameters.forEach((parameter) => {
          let input = parameter.querySelector(".cookie-parameter__input");
          let labelPre = parameter.querySelector(".cookie-parameter__label");
          let label = labelPre.htmlFor;

          if (input.checked === true) {
            localStorage.setItem(label, "true");
          } else {
            localStorage.removeItem(label);
          }
        });
      } else {
        const inputs = cookieBanner.querySelectorAll(
          ".cookie-parameter__input-editable"
        );

        inputs.forEach((input) => {
          const id = input.getAttribute("id");
          const value = localStorage.getItem(id);
          if (value === "true") {
            input.checked = true;
          } else {
            input.checked = false;
          }
        });
      }
    }

    cookieAcceptButton.addEventListener("click", () => {
      handleCookieBanner(true);
    });

    cookieCancelButton.addEventListener("click", () => {
      handleCookieBanner(false);
    });

    const switchState = (input) => input.click();

    cookieParameters.forEach((parameter) => {
      const input = parameter.querySelector(".cookie-parameter__input");
      const labelElement = parameter.querySelector(".cookie-parameter__label");
      const labelPre = parameter.querySelector(".cookie-parameter__label");
      const label = labelPre.htmlFor;

      if (localStorage.getItem(label) === "true") {
        switchState(input);
      }

      // This only applies to firefox
      if (navigator.userAgent.match(/firefox|fxios/i)) {
        labelElement.addEventListener("click", () => {
          switchState(input);
        });
      }

      input.addEventListener("click", () => {
        switchState(input);
      });

      // it should work also with keyboard navigation
      input.addEventListener("keyup", (event) => {
        if (event.key === "Enter" || event.key === "Space" ) {
          switchState(input);
        }
      });
    });
  }
}

export function isCookieBannerOpen() {
  if (cookieBanner.classList.contains("active")) {
    cookieCancelButton.tabIndex = 0;
    cookieAcceptButton.tabIndex = 0;
    cookieParameters.forEach((parameter) => {
      parameter.querySelector("input").tabIndex = 0;
    });
    cookieLinks.forEach((link) => {
      link.tabIndex = 0;
    });
    openButton.setAttribute('tabindex', '0');
    cookieBanner.removeAttribute("aria-hidden");
  } else {
    cookieCancelButton.tabIndex = -1;
    cookieAcceptButton.tabIndex = -1;
    cookieParameters.forEach((parameter) => {
      parameter.querySelector("input").tabIndex = -1;
    });
    cookieLinks.forEach((link) => {
      link.tabIndex = -1;
    });
    openButton.setAttribute('tabindex', '-1');
    cookieBanner.setAttribute("aria-hidden", "true");
  }
}

export async function initCookieDetails() {
  const detailsCookies = document.querySelector("[data-details-cookies]");
  const closeButton = document.querySelector("[data-close-details-cookies]");
  if (!openButton || !detailsCookies) return;

  openButton.addEventListener("click", () => {
    detailsCookies.classList.add("active");
    openButton.setAttribute('tabindex', '-1');
    closeButton.setAttribute('tabindex', '0');
  });

  closeButton.addEventListener("click", () => {
    detailsCookies.classList.remove("active");
    openButton.setAttribute('tabindex', '0');
    closeButton.setAttribute('tabindex', '-1');
  });
}