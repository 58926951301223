import Splide from "@splidejs/splide";
import { initUseCompactPagination } from "./utils";
import { removeTabIndexFromSlides } from ".";

/**
 * Creates a splide slider for a given element
 *
 * @param slider
 */
export function initArticleSlider(slider) {
  const sliderOptions = {
    type: "slide",
    perPage: 5,
    perMove: 4,
    autoWidth: true,
    arrows: true,
    pagination: true,
    keyboard: false,
    drag: true,
    arrowPath:
      "M12 9.5 2.383 0 .121 2.235 7.475 9.5.12 16.765 2.383 19 12 9.5z",
    breakpoints: {
      1024: {
        perPage: 1,
      },
      1340: {
        perPage: 3,
      },
      1670: {
        perPage: 4,
      },
    },
    classes: {
      pagination: "tns-nav",
      page: "tns-nav-page",
      arrows: "tns-controls",
      arrow: "",
      prev: "splide-arrow--prev",
      next: "splide-arrow--next",
    },
  };

  const splide = new Splide (slider, sliderOptions)

  splide.on("mounted", function () {
    removeTabIndexFromSlides();
  });

  initUseCompactPagination(splide);

  return splide.mount();
}

export function initArticleRelatedSlider(slider) {
  const sliderOptions = {
    type: "slide",
    perPage: 4,
    perMove: 1,
    arrows: true,
    gap: "1.5rem",
    pagination: true,
    keyboard: false,
    drag: true,
    arrowPath:
      "M12 9.5 2.383 0 .121 2.235 7.475 9.5.12 16.765 2.383 19 12 9.5z",
    breakpoints: {
      768: {
        perPage: 1,
        padding: "30px",
      },
      1224: {
        perPage: 2,
        padding: "30px",
      },
      2000: {
        perPage: 4,
      },
    },
    classes: {
      pagination: "tns-nav",
      page: "tns-nav-page",
      arrows: "tns-controls",
      prev: "splide-arrow--prev",
      next: "splide-arrow--next",
    },
  };

  const splide = new Splide (slider, sliderOptions)

  splide.on("mounted", function () {
    removeTabIndexFromSlides();
  });

  initUseCompactPagination(splide);

  return splide.mount();
}