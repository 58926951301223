import { $, $$ } from "./utils";

let countAllResults = 0;
export const initSearchModal = async () => {
  initFormSubmit();
  initSearchInput();
  const searchIcon = $$(".search-toggle, .search-input");
  const searchModal = $(".search-open");
  if (searchIcon) {
    const inputField = $(".search-form__input-text");
    const searchButton = $(".search-form__input-btn");
    const searchInputModalMobile = $(".search-input");
    const closeButtons = $$(".close-search-btn");
    const eraseButton = $(".delete-input-btn");
    const logo = $(".search-open .brand-link");
    const searchResultFavoriteCategoriesLinks = document.querySelectorAll(
      ".search-results-favorite-categories li a"
    );
    let ariaState = searchIcon.forEach(icon => icon.getAttribute("aria-expanded"));

    logo?.setAttribute("tabindex", "-1");
    searchResultFavoriteCategoriesLinks.forEach((link) => {
      link.setAttribute("tabindex", "-1");
    });

    searchIcon.forEach(icon => {
      icon.addEventListener("click", () => {
        searchModal.classList.add("active");
        ariaState = true;
        icon.setAttribute("aria-expanded", ariaState);
        searchResultFavoriteCategoriesLinks.forEach((link) => {
          link.setAttribute("tabindex", "0");
        });
        closeButtons.forEach((button) => {
          button.setAttribute("tabindex", "0");
        });
        logo.setAttribute("tabindex", "0");
        eraseButton.setAttribute("tabindex", "0");
        inputField.setAttribute("tabindex", "0");
        searchButton.setAttribute("tabindex", "0");
        inputField.focus();
        placeholderTypewriter();
      });
    });

    closeButtons.forEach(button => {
      button.addEventListener("click", () => {
        eraseButton.setAttribute("tabindex", "-1");
      });
    })

    searchInputModalMobile.addEventListener("click", () => {
      searchModal.classList.add("active");
      ariaState = true;
      searchIcon.forEach(icon => icon.setAttribute("aria-expanded", ariaState));
    });

    closeButtons.forEach((button) => {
      button.addEventListener("click", () => {
        searchModal.classList.remove("active");
        ariaState = false;
        searchIcon.forEach(icon => icon.setAttribute("aria-expanded", ariaState));
        button.setAttribute("tabindex", "-1");
        logo.setAttribute("tabindex", "-1");
        inputField.setAttribute("tabindex", "-1");
        searchButton.setAttribute("tabindex", "-1");
        searchResultFavoriteCategoriesLinks.forEach((link) => {
          link.setAttribute("tabindex", "-1");
        });
      });
    });

    if (eraseButton) {
      eraseButton.addEventListener("click", () => {
        if (inputField.value !== "") {
          inputField.value = "";
        }
      });
    }

    // Create small animation for the placeholder
    let index = 0;
    let placeholder = "";
    const textPlaceholder = "Suchbegriff eingeben";
    const speed = 90;

    function placeholderTypewriter() {
      placeholder = '';
      for (let index = 0; index < textPlaceholder.length; index++) {
        setTimeout(() => {
          placeholder += textPlaceholder.charAt(index);
          inputField.setAttribute("placeholder", placeholder);
        }, (speed + 1) * index);
      }
    }
  }

  initTabFunction();
};

const initTabFunction = () => {
  const filterTabs = document.querySelectorAll(".search-tab");
  const articles = document.querySelectorAll(".search-articles__list-item");

  filterTabs.forEach((tab) => {
    tab.addEventListener("click", (event) => {
      event.preventDefault();
      const filter = event.target.dataset.filter;

      if (tab.classList.contains("active")) {
        tab.classList.remove("active");
      } else {
        tab.classList.add("active");
      }

      articles.forEach((article) => {
        if (filter === "alle") {
          article.style.display = "flex";
        } else {
          if (article.classList.contains(filter)) {
            article.style.display = "flex";
          } else {
            article.style.display = "none";
          }
        }
      });
    });
  });
};

const initFormSubmit = async () => {
  const searchForm = document.querySelector(".search-form");
  const searchInput = document.querySelector('input[name="tx_solr[q]"]');
  const searchResults = document.querySelector(".search-results");

  if(!searchForm) {
    return;
  }

  searchForm.addEventListener("submit", (event) => {
    event.preventDefault();
    const pageType = 1641895394;
    let searchValue = searchInput.value;

    if (searchValue === "") {
      return;
    }

    fetch(`/?tx_solr[q]=${searchValue}&type=${pageType}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
    })
      .then((result) => {
        return result.text();
      })
      .then((html) => {
        searchResults.innerHTML = html;
        countAllResults = document.querySelector(".search-tab:first-of-type")
          .dataset.countAllResults;
        initFilterLink(countAllResults);
      });
  });
};

const initFilterLink = async () => {
  const filters = document.querySelectorAll(".search-tab");
  const searchResults = document.querySelector(".search-results");
  changeViewButton();
  filters.forEach((filter) => {
    filter.addEventListener("click", (event) => {
      event.preventDefault();
      fetch(filter.href, {
        method: "GET",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
      })
        .then((result) => {
          return result.text();
        })
        .then((html) => {
          searchResults.innerHTML = html;
          initFilterLink();
          const countAllResultsEle = document.querySelector(
            ".search-tab:first-of-type"
          );
          countAllResultsEle.innerHTML = "Alle (" + countAllResults + ")";
        });
    });
  });
};

const changeViewButton = () => {
  const anchor = document.querySelector(".change-filter-display-js");
  const tabList = document.querySelector(".search-tabs__list");
  let displayValue = window.getComputedStyle(tabList).display;
  if (anchor) {
    anchor.addEventListener("click", (element) => {
      element.preventDefault();
      element.stopPropagation();
      if (displayValue === "flex") {
        displayValue = "block";
      } else {
        displayValue = "flex";
      }
      tabList.style.cssText += "display: " + displayValue + ";";
    });
  }
};

const initSearchInput = async () => {
  const searchInput = document.querySelector(".search-form__input-text");
  const searchButton = document.querySelector(".delete-input-btn");
  // let displayValue = window.getComputedStyle(searchButton).display;
  
  if(!searchInput) {
    return;
  }

  searchInput.addEventListener("keyup", (element) => {
    if (searchInput.value.length > 0) {
      searchButton.classList.add("show");
    } else {
      searchButton.classList.remove("show");
    }
  });
};
