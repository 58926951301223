export const initHeroVideoDownloadModal = () => {
  const cookieButton = document.querySelector(".cookie-button");
  const videoHeroSection = document.querySelector(".hero-section");
  const videoDownloadHeroWrap = document.querySelector(".hero-download-wrap");
  const videoDownloadModal = document.querySelector(
    ".hero-download-modal-wrap"
  );

  // Variables for the download of the video
  const downloadVideoButton = document.querySelector(".btn-download-hero-item");
  const videoLanguageSelector = document.querySelectorAll(
    'input[name="video-language"]'
  );

  // Handle opening and closing the hero modal
  if (videoHeroSection && videoDownloadHeroWrap) {
    const openVideoDownloadModalButton =
      videoDownloadHeroWrap.querySelector(".btn-download");
    const closeVideoDownloadModalButton = videoDownloadModal.querySelector(
      ".hero-download-close-button"
    );
    openVideoDownloadModalButton.addEventListener("click", () => {
      videoHeroSection.style.position = "initial";
      // Close other modals that could be opened
      cookieButton.classList.remove("active");
      document.querySelectorAll(".article-download-modal").forEach((modal) => {
        modal.classList.remove("active");
      });
      videoDownloadModal.classList.add("active");
      setTabindex(0);
    });

    if (videoDownloadModal.classList.contains("active")) {
      cookieButton.classList.remove("active");
    } else {
      cookieButton.classList.add("active");
    }

    closeVideoDownloadModalButton.addEventListener("click", () => {
      videoDownloadModal.classList.remove("active");
      cookieButton.classList.add("active");
      if (videoLanguageSelector) {
        videoLanguageSelector.forEach((languagePill) => {
          languagePill.checked = false;
        });
      }
      downloadVideoButton.classList.add("btn-disabled");
      setTabindex(-1);
    });

    // Handle download video
    // Cancel download possibility if no radio button is selected
    downloadVideoButton.addEventListener("click", (event) => {
      if (downloadVideoButton.classList.contains("btn-disabled")) {
        event.preventDefault();
      }
    });

    if (videoLanguageSelector) {
      videoLanguageSelector.forEach((languagePill) => {
        languagePill.addEventListener("click", () => {
          // videoLanguageSelector.forEach((pill) => {
          //   pill.setAttribute('aria-checked', 'false');
          // })

          if (languagePill.checked === true) {
            let downloadData = languagePill.getAttribute("data-download");
            // languagePill.setAttribute("aria-checked", "true");
            downloadVideoButton.setAttribute("download", downloadData);
            downloadVideoButton.setAttribute("href", downloadData);
            downloadVideoButton.classList.remove("btn-disabled");
            downloadVideoButton.tabIndex = 0;
          } else {
            downloadVideoButton.classList.add("btn-disabled");
          }
        });
      });
    }
  }
};

function setTabindex(value){
  const inputs = document.querySelectorAll('.hero-download-modal-wrap input');
  const anchors = [...document.querySelectorAll('.hero-download-modal-wrap a')].filter(button=> !button.classList.contains('btn-disabled'));
  const buttons = document.querySelectorAll('.hero-download-modal-wrap button')
  
  const listOfAll = [...inputs,...anchors,...buttons];
  listOfAll.forEach(element=>{
    if(element.tabIndex !== value){
      element.tabIndex = value
    }
  })
}
