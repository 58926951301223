// @ts-check

import Splide from "@splidejs/splide";
import { tns } from "tiny-slider/src/tiny-slider";
import { $$ } from "../utils";
import { initArticleRelatedSlider, initArticleSlider } from "./articleSlider";
import {
  CIRCLE_WIDTH,
  MAX_CIRCLES_PER_LINE,
  MAX_ELEMENTS_UNTIL_PAGINATION,
} from "./constants";
import { initUseCompactPagination } from "./utils";

export function initSlider() {
  const sliders = $$(".slider");
  const gallerySlider = $$(".gallery-slider");
  const bigImageMobileSliders = $$(".images-slider__slider");
  const teaserSliders = $$(".teaser-slider");
  const articleThemeSlidersSelfmade = $$(".article-theme-slider--diy");
  const articleThemeSlidersFlocker = $$(".article-theme-slider--flockler");
  const articleThemeSliderHero = $$(
    ".hero-container-article-theme-slider .article-theme-slider--flockler .splide__list"
  );

  const ArticleThemeSlidersFlockerWithoutNewsroom = [
    ...articleThemeSlidersFlocker,
  ].filter((element) => {
    // @ts-ignore
    return !element.parentElement.parentElement.parentElement.classList.contains(
      "hero-container-article-theme-slider"
    );
  });

  for (const slider of sliders) {
    const sliderObject = tns({
      preventScrollOnTouch: "auto",
      container: slider,
      autoplay: false,
      loop: true,
      arrowKeys: true,
      items: 1,
      responsive: {
        1025: {
          disable: true,
        },
      },
    });

    sliderObject.events.on("indexChanged", () => {
      addAriaLabelToNavs();
    });

    addAriaLabelToNavs();
    addAriaLabelToControls();
  }

  // Image Gallery and Highlight box image gallery
  for (const slider of gallerySlider) {
    // @ts-ignore
    const sliderObject = initGallerySlider(slider);


    // sliderObject.events.on("indexChanged", () => {
    //   addAriaLabelToNavs();
    // });

    // addAriaLabelToNavs();
    // addAriaLabelToControls();
  }

  for (const slider of bigImageMobileSliders) {
    const sliderObject = tns({
      preventScrollOnTouch: "auto",
      container: slider,
      autoplay: false,
      loop: false,
      mouseDrag: true,
      slideBy: 1,
      items: 1,
      gutter: 10,
      arrowKeys: true,

      responsive: {
        768: {
          disable: true,
        },
      },
    });

    sliderObject.events.on("indexChanged", () => {
      addAriaLabelToNavs();
    });

    addAriaLabelToNavs();
    addAriaLabelToControls();
  }

  for (const slider of teaserSliders) {
    const sliderObject = tns({
      preventScrollOnTouch: "auto",
      container: slider,
      autoplay: false,
      loop: false,
      edgePadding: 15,
      controlsText: [
        '<svg xmlns="http://www.w3.org/2000/svg" width="7.264" height="12.406" viewBox="0 0 7.264 12.406"><g data-name="Pfeil rechts Small" transform="translate(0.53 0.53)"><path data-name="Pfeil rechts" d="M-13325.159-5531l5.673-5.672-5.673-5.673" transform="translate(13325.159 5542.345)" fill="none" stroke="#191919" stroke-width="1.5"/></g></svg>',
        '<svg xmlns="http://www.w3.org/2000/svg" width="7.264" height="12.406" viewBox="0 0 7.264 12.406"><g data-name="Pfeil rechts Small" transform="translate(0.53 0.53)"><path data-name="Pfeil rechts" d="M-13325.159-5531l5.673-5.672-5.673-5.673" transform="translate(13325.159 5542.345)" fill="none" stroke="#191919" stroke-width="1.5"/></g></svg>',
      ],

      responsive: {
        1025: {
          mouseDrag: false,
        },
      },
    });

    sliderObject.events.on("indexChanged", () => {
      addAriaLabelToNavs();
    });

    addAriaLabelToNavs();
    addAriaLabelToControls();
  }

  for (const slider of ArticleThemeSlidersFlockerWithoutNewsroom) {
    const sliderObject = initArticleSlider(slider);

    // sliderObject.events.on("indexChanged", () => {
    //   addAriaLabelToNavs();
    // });
    addAriaLabelToNavs();
    addAriaLabelToControls();
    // @ts-ignore
    if (!window.articleThemeSlider) {
      // @ts-ignore
      window.articleThemeSlider = [];
      // @ts-ignore
      window.articleThemeSlider.push(sliderObject);
    }
  }

  for (const slider of articleThemeSliderHero) {
    const sliderObject = tns({
      preventScrollOnTouch: "auto",
      container: slider,
      autoplay: false,
      mouseDrag: true,
      loop: false,
      slideBy: 1,
      items: 1,
      edgePadding: 20,
      gutter: 10,
      arrowKeys: true,
      controlsText: [
        '<svg width="12" height="19" xmlns="http://www.w3.org/2000/svg"><path d="M12 9.5 2.383 0 .121 2.235 7.475 9.5.12 16.765 2.383 19 12 9.5z" fill="#000" fill-rule="nonzero"/></svg>',
        '<svg width="12" height="19" xmlns="http://www.w3.org/2000/svg"><path d="M12 9.5 2.383 0 .121 2.235 7.475 9.5.12 16.765 2.383 19 12 9.5z" fill="#000" fill-rule="nonzero"/></svg>',
      ],
      responsive: {
        1030: {
          items: 2.8,
          gutter: 30,
        },
        1200: {
          items: 3.5,
        },
        1400: {
          items: 4,
        },
        1900: {
          gutter: 30,
          items: 5,
        },
      },
    });

    sliderObject.events.on("indexChanged", () => {
      addAriaLabelToNavs();
    });
    addAriaLabelToNavs();
    addAriaLabelToControls();
  }

  for (const slider of articleThemeSlidersSelfmade) {
    const sliderObject = initArticleRelatedSlider(slider);

    // addAriaLabelToNavs();
    // addAriaLabelToControls();

    // @ts-ignore
    if (!window.articleThemeSlider) {
      // @ts-ignore
      window.articleThemeSlider = [];
      // @ts-ignore
      window.articleThemeSlider.push(sliderObject);
    }
  }

  initHandleDotNavigation();
}

export const removeTabIndexFromSlides = () => {
  const slides = document.querySelectorAll(".splide__slide");
  if(!slides) return;

  slides.forEach((slide) => {
    slide.setAttribute("tabindex", "-1");
  });
};

const addAriaLabelToControls = () => {
  const controls = document.querySelectorAll(".tns-controls");
  if (controls) {
    controls.forEach((controlElement) => {
      let previousButton = controlElement.children[0];
      let nextButton = controlElement.children[1];

      controlElement.setAttribute("tabindex", "-1");
      nextButton.setAttribute("tabindex", "0");
      previousButton.setAttribute("tabindex", "0");
      controlElement.removeAttribute("aria-label");
      previousButton.setAttribute("aria-label", "Vorheriges Bild");
      nextButton.setAttribute("aria-label", "Nächstes Bild");
    });
  }
};

// This is now adapted to Splide JS, but still admits tiny slider in the else response
const addAriaLabelToNavs = () => {
  const navs = document.querySelectorAll(".tns-nav");
  if (navs) {
    navs.forEach((navElement) => {
      navElement.removeAttribute("aria-label");
      navElement.setAttribute("tabindex", "-1");
      let navGroup = [...navElement.children];
      navGroup.forEach((element, index) => {
        element.setAttribute("tabindex", "0");
        let newAriaLabel = "Bild " + (index + 1);
        let button = element.querySelector("button");
        if (button) {
          button.setAttribute("aria-label", newAriaLabel);

          if (button.classList.contains("is-active")) {
            newAriaLabel = "ausgewähltes Bild " + (index + 1);
            button.setAttribute("aria-label", newAriaLabel);
          }
        } else {
          // It is still tiny slider
          element.setAttribute("aria-label", newAriaLabel);

          if (element.classList.contains("tns-nav-active")) {
            newAriaLabel = "ausgewähltes Bild " + (index + 1);
            element.setAttribute("aria-label", newAriaLabel);
          }
        }
      });
    });
  }
};

function initGallerySlider(slider) {
  // eslint-disable-next-line unicorn/consistent-function-scoping
  function checkAltSize() {
    let galleries = document.querySelectorAll(".gallery-slider");
    let windowWidth = window.innerWidth;

    galleries.forEach((gallery) => {
      let allFigures = gallery.querySelectorAll(
        ".splide__list figure:not(.splide__slide--clone)"
      );
      allFigures.forEach((figure) => {
        let image = figure.querySelector("img");
        let desktopAlt = figure.getAttribute("data-desktop-alt");
        let tabletAlt = figure.getAttribute("data-tablet-alt");
        let mobileAlt = figure.getAttribute("data-mobile-alt");

        if (windowWidth > 768) {
          // @ts-ignore
          if (desktopAlt.length > 0) {
            // @ts-ignore
            image.setAttribute("alt", desktopAlt);
          }
        }

        if (windowWidth < 767) {
          // @ts-ignore
          if (tabletAlt.length > 0) {
            // @ts-ignore
            image.setAttribute("alt", tabletAlt);
          }
        }

        if (windowWidth < 575) {
          // @ts-ignore
          if (mobileAlt.length > 0) {
            // @ts-ignore
            image.setAttribute("alt", mobileAlt);
            // @ts-ignore
          } else if (tabletAlt.length > 0) {
            // @ts-ignore
            image.setAttribute("alt", tabletAlt);
          }
        }
      });
    });
  }

  const sliderOptions = {
    type: "loop",
    // perPage: 1,
    perMove: 1,
    autoWidth: true,
    arrows: true,
    pagination: false,
    keyboard: false,
    drag: true,
    slideFocus: false,
    arrowPath:
      "M12 9.5 2.383 0 .121 2.235 7.475 9.5.12 16.765 2.383 19 12 9.5z",
    breakpoints: {
      768: {
        perPage: 1,
        pagination: true,
      },
    },
    classes: {
      pagination: "tns-nav",
      page: "tns-nav-page",
      arrows: "tns-controls",
      arrow: "",
      prev: "splide-arrow--prev",
      next: "splide-arrow--next",
    },
  };

  let splide = new Splide(slider, sliderOptions);

  splide.on("mounted", function () {
    checkAltSize();
    removeTabIndexFromSlides();
  });

  splide.on("resized", function () {
    checkAltSize();
  });

  initUseCompactPagination(splide);
 

  return splide.mount();
}

function initHandleDotNavigation() {
  // Relocate Slider Navigation Dots if length > 7 in mobile
  const allSliderNavs = document.querySelectorAll(".tns-nav");

  if (!allSliderNavs) return;

  allSliderNavs.forEach((dotNav) => {
    const elements = dotNav.querySelectorAll("li");
    const allArticlesButton =
      dotNav?.parentElement?.parentElement?.querySelector(
        ".button-section--right"
      );

    if (elements.length < 0 || !elements) return;

    function setCircleWrapperMaxWidth() {
      const NUM_LINES = elements.length > MAX_CIRCLES_PER_LINE ? 2 : 1;
      // console.log('This is the NUM_LINES', NUM_LINES)
      if (elements.length > MAX_CIRCLES_PER_LINE) {
        const circlesPerLine = Math.min(
          MAX_CIRCLES_PER_LINE,
          Math.ceil(elements.length / NUM_LINES)
        );

        const maxWidth = circlesPerLine * CIRCLE_WIDTH + 5 * circlesPerLine;

        // @ts-ignore
        dotNav.style.maxWidth = `${maxWidth}px`;
      }
    }

    if (elements.length <= MAX_ELEMENTS_UNTIL_PAGINATION) {
      setCircleWrapperMaxWidth();
    }

    function teaserTileNavChecker() {
      if (dotNav.clientHeight > 30 && window.outerWidth < 768) {
        // @ts-ignore
        // THIS APPLIES TO THE PAGINATION
        dotNav.style.bottom = "-50px";
        // @ts-ignore
        if (allArticlesButton) allArticlesButton.style.bottom = "-55px";
      } else {
        if (
          dotNav.parentElement?.classList.contains("article-theme-slider--diy") || 
          dotNav.parentElement?.classList.contains("article-theme-slider--flockler")
        ) {
          // @ts-ignore
          // THIS APPLIES TO THE DOTS NAVIGATION (LEFT FROM ALLE ANZEIGEN BUTTON)
          dotNav.style.bottom = "-45px";
        } else {
          // @ts-ignore
          // THIS APPLIES TO THE DOTS WHEN THERE IS NOT ALLE ANZEIGEN BUTTON
          // E.g. GALLERY SLIDER, HIGHLIGHT BOX SLIDER, 50/50 SLIDER
          dotNav.style.bottom = "-36px";
        }
        // @ts-ignore
        // THIS APPLIES TO THE ALLE ANZEIGEN BUTTON
        if (allArticlesButton) allArticlesButton.style.bottom = "-55px";
      }
    }
    teaserTileNavChecker();

    window.addEventListener("resize", teaserTileNavChecker);
  });
}
