import gsap from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";

export function initPeopleBoxAnimation() {
  const pbWrapper = document.querySelectorAll(".people-box__wrapper");

  if (pbWrapper) {
    gsap.utils.toArray(pbWrapper).forEach((element) => {
      gsap.registerPlugin(ScrollTrigger);
      const item = element.querySelectorAll("li");
      if(!item) return;

      const elementTimeline = gsap.timeline({
        defaults: { duration: 0.5, stagger: 0.15 },
        scrollTrigger: {
          trigger: element,
          start: "top 85%",
          end: "bottom center",
        },
      });

      elementTimeline.fromTo(
        item,
        {
          opacity: 0,
          y: 200,
        },
        {
          opacity: 1,
          y: 0,
        }
      );
    });
  }
}
