export async function initContrastSwitcher() {
  const storageName = 'contrast-on';
  const isContrastOn = localStorage.getItem(storageName);
  let contrastButton = document.querySelector('.contrast-toggle');
  let buttonText = contrastButton.querySelector('span');

  if (isContrastOn) {
    let body = document.querySelector('body');
    buttonText.innerHTML = 'normaler kontrast';
    body.classList.add(storageName);
  }

  contrastButton.addEventListener('click', function(event) {
    event.preventDefault();

    if (isContrastOn) {
      localStorage.removeItem(storageName);
    } else {
      const date = new Date(new Date().setFullYear(new Date().getFullYear() + 1));
      const parameters = {
        value: storageName,
        expiry: date.getTime()
      };
      localStorage.setItem(storageName, parameters);
    }
    // eslint-disable-next-line no-restricted-globals
    location.reload();
  });
}
