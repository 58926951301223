import { $$ } from '../utils';

const focusClass = 'child-input--focus';
const focusTextAreaClass = 'child-textarea';
const filledClassName = 'input--filled';

/**
 * checks if Input is filled on load and on keydown and adds class for styling
 * @return {void}
 */
export function initInputFilled() {
  $$(
    'input:not([type="hidden"]):not([type="checkbox"]):not([type="radio"]):not([type="select"]):not(.tx-indexedsearch-searchbox-sword), textarea:not([type="hidden"])'
  ).forEach((input) => {
    const isTextArea = input.tagName.toLocaleLowerCase() === 'textarea';
    const parentGroup = input.closest('.form-group');

    if (parentGroup) {
      if (isTextArea)
        parentGroup.classList.add(focusTextAreaClass);

      setTimeout(() => {
        isFilled(input);
      }, 150);
      input.addEventListener('keydown', () => isFilled(input));
      input.addEventListener('blur', () => isFilled(input));
      input.addEventListener('focusin', () => {
        parentGroup.classList.add(focusClass);
      });
      input.addEventListener('focusout', () => {
        parentGroup.classList.remove(focusClass);
      });
    }
  });
}
function isFilled(input) {
  const parentGroup = input.closest('.form-group');

  if (parentGroup) {
    input.classList[input.value ? 'add' : 'remove'](filledClassName);
    parentGroup.classList[input.value ? 'add' : 'remove'](`child-${filledClassName}`);
  }
}
