export const initEditBasket = () => {
  const editForm = document.querySelector("#edit-order-form");
  const editButtons = document.querySelectorAll(".edit-order-btn");
  if (editButtons && editForm) {
    // The button only comes if java script is activated
    editButtons.forEach((editButton) => {
      if (editButton.classList.contains("no-js")) {
        editButton.classList.remove("no-js");
      }
      editButton.addEventListener("click", () => {
        editForm.submit();
      });
    });
  }
};

export const scrollToError = () => {
  const errors = document.querySelectorAll(".error");
  if (errors) {
    let count=0;
    errors.forEach((error) => {

      if(!isHidden(error) && count === 0 ) {
        count=1;
        let idOfError = error.id;
        document.querySelector('#'+idOfError).scrollIntoView();
      }

    });
  }
};

function isHidden(element) {
  let style = window.getComputedStyle(element);
  return (style.display === 'none')
}
