import { isCookieBannerOpen } from "./cookie";

const YOUTUBE_ID = "YouTube-id";

export async function initVideo() {
  const cookieBanner = document.querySelector(".cookie-banner");
  const cookieButton = document.querySelector(".cookie-button");
  const cookieOptions = document.querySelector(".cookie-options");
  const videoTeasers = document.querySelectorAll(".video-teaser");
  let isYouTubeAccepted = localStorage.getItem(YOUTUBE_ID);

  if (videoTeasers) {
    videoTeasers.forEach((teaser) => {
      const videoModal = teaser.querySelector(".video-teaser__modal");
      const audioButton = teaser.querySelector(".audio-switch");
      const playButton = teaser.querySelector(".video-teaser__button-play");
      const closeModalButton = teaser.querySelector(
        ".video-teaser__modal-close"
      );
      const openCookieButton = teaser.querySelector(
        ".video-teaser__modal-cookie"
      );

      const videoIframeContainer = teaser.querySelector(".video-iframe-container");

      if (videoIframeContainer) {
        const youtubeIdWithoutAudiodescription = teaser.querySelector(
          ".video-iframe-container"
        ).dataset.id;
        const youtubeIdWithAudiodescription = teaser.querySelector(
          ".video-iframe-container"
        ).dataset.audiodescription;

        if (isYouTubeAccepted) {
          videoModal.setAttribute("aria-hidden", "true");
        }

        audioButton.addEventListener("click", () => {
          const videoIframe = teaser.querySelector(".video-iframe-container iframe");

          if (audioButton.classList.contains("audiodescription-switch-off")) {
            setTimeout(() => {
              activateAudiodescription(audioButton);
              loadVideo(videoIframe, youtubeIdWithAudiodescription);
            }, 300);
          } else {
            setTimeout(() => {
              deactivateAudiodescription(audioButton);
              loadVideo(videoIframe, youtubeIdWithoutAudiodescription);
            }, 300);
          }
        });

        playButton.addEventListener("click", () => {
          if(!playButton.classList.contains('pressed')) {
            // console.log('gerxdf');
            playButton.classList.add('pressed');
            // Handle the case without cookies being accepted
            if (!localStorage.getItem(YOUTUBE_ID)) {
              videoModal.classList.add("active");
              playButton.classList.remove('pressed');
              setVideoModalButtonsTabIndex(videoModal, true);
            } else {
              videoModal.classList.remove("active");
              videoModal.setAttribute("aria-hidden", "true");
              // Remove Play button
              playButton.remove();
              // Display audiodescription button only if there is the option
              if (youtubeIdWithAudiodescription.length > 0) {
                audioButton.classList.add("active");
                setVideoModalButtonsTabIndex(videoModal, true);
              }

              const videoIframe = document.createElement("iframe");
              videoIframe.classList.add("video-iframe");
              videoIframe.setAttribute("allowfullscreen", "");
              videoIframe.setAttribute("allow", "autoplay");
              videoIframe.setAttribute("width", "100%");
              videoIframe.setAttribute("height", "100%");
              videoIframe.setAttribute("class", "super");
              videoIframe.setAttribute("frameborder", "0");
              videoIframe.setAttribute("src", "https://www.youtube-nocookie.com/embed/" + youtubeIdWithoutAudiodescription);
              videoIframeContainer.appendChild(videoIframe);

              // Load one of the two versions of the video
              if (audioButton.classList.contains("audiodescription-switch-off")) {
                loadVideo(videoIframe, youtubeIdWithoutAudiodescription);
              } else {
                loadVideo(videoIframe, youtubeIdWithAudiodescription);
              }
            }
          }
        });

        closeModalButton.addEventListener("click", () => {
          videoModal.classList.remove("active");
          setVideoModalButtonsTabIndex(videoModal, false);
        });

        openCookieButton.addEventListener("click", () => {
          videoModal.classList.remove("active");
          setVideoModalButtonsTabIndex(videoModal, false);
          videoModal.setAttribute("aria-hidden", "true");
          cookieBanner.classList.add("active");
          cookieButton.classList.remove("active");

          isCookieBannerOpen();

          if(cookieOptions) {
            const inputElement = cookieOptions.querySelector(
              `input[id="${YOUTUBE_ID}"]`
            );
            inputElement.focus();
          }
        });
      }
    });
  }
}

function activateAudiodescription(element) {
  element.classList.remove("audiodescription-switch-off");
  element.ariaLabel = "Video mit Audiodeskription (aktiviert)";
  element.title = "Video mit Audiodeskription (aktiviert)";
}

function deactivateAudiodescription(element) {
  element.classList.add("audiodescription-switch-off");
  element.ariaLabel = "Video mit Audiodeskription (deaktiviert)";
  element.title = "Video mit Audiodeskription (deaktiviert)";
}

// TODO: fix
function loadVideo(iframe, id) {
  iframe.parentElement.classList.add("active");
  iframe.src = "https://www.youtube-nocookie.com/embed/" + id;
}

function setVideoModalButtonsTabIndex(element, bool) {
  const buttons = element.querySelectorAll("button");
  buttons.forEach((button) => {
    if (bool) button.tabIndex = "0";
    else button.tabIndex = "-1";
  });
}
