/**
 * Main entrypoint to the JavaScript dependency tree
 */
/*eslint-disable */

import "./js/vendor/lazysizes.min.js";

import { initContrastSwitcher } from "./js/contrastSwitcher";
import { initCookie, initCookieDetails } from "./js/cookie";
import { initCopyUrl } from "./js/copyUrl";
import { initContactForm } from "./js/form/contact";
import { initForm } from "./js/form/index";
import { initLeaflet } from "./js/leaflet";
import { initLoadMore } from "./js/loadMore";
import { initNavigation } from "./js/navigation";
import { initPopup } from "./js/popup";
import { initReadMore } from "./js/readMore";
import { initMessages } from "./js/screen-cube/messages";
import { initModal } from "./js/screen-cube/modal";
import { initSmoothScroll } from "./js/scrollToTop";
import { initSearchModal } from "./js/search";
import { initSlider, removeTabIndexFromSlides } from "./js/slider";
import { initPill, threeCols } from "./js/threeCols";
import { initVideo } from "./js/video";
// import { initMargin } from "./js/articleThemeMargin";
import { initAudioPlayer } from "./js/audioPlayer";
import { initHeroVideoDownloadModal } from "./js/heroDownload";
import { initHeroSlider } from "./js/heroSlider.js";
import { initEditBasket, scrollToError } from "./js/shop/order";
import {
  initAddCartButton,
  initOpenDownloadShopModal,
  initScrollToBasket,
  verifyOrder,
} from "./js/shop/shop";
// import { initAnimatedTeaser } from "./js/teaser.js";
import { initAudioPlayerNew } from "./js/audioPlayerNew.js";
import { initPeopleBoxAnimation } from "./js/peopleBoxAnimation.js";
import { initSocialEmbed } from "./js/socialEmbed.js";
import { initSocialEmbedAnimation } from "./js/socialEmbedAnimation.js";
import { initRemoveTabIndexFromPlayButtonsInCards } from "./js/cards.js";


window.addEventListener("load", () => {
  initSlider();
  // initMargin();
});
window.addEventListener("resize", initPill);

document.addEventListener("DOMContentLoaded", () => {
  initPopup();
  initModal();
  initMessages(-1);
  initNavigation();
  initHeroSlider();
  initContrastSwitcher();
  initSmoothScroll();
  initSearchModal();
  initLoadMore();
  initCopyUrl();
  initForm();
  initCookie();
  initCookieDetails();
  threeCols();
  initLeaflet();
  initReadMore();
  initVideo();
  initSocialEmbed();
  // initAnimatedTeaser(); Not used anymore - vintage code
  initContactForm();
  initShop();
  initPill();
  initAudioPlayerNew();
  initPeopleBoxAnimation();
  initSocialEmbedAnimation();
  // initRemoveTabIndexFromPlayButtonsInCards();

  removeTabIndexFromSlides();
});

function initShop() {
  initScrollToBasket();
  initHeroVideoDownloadModal();
  initOpenDownloadShopModal();
  initAudioPlayer();
  initEditBasket();
  initAddCartButton();
  verifyOrder();
  scrollToError();
}
