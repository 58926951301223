export async function initCopyUrl() {
  const copyToClipboardButtons = document.querySelectorAll(".copy__item");
  const currentUrl = window.location.href;
  copyToClipboardButtons.forEach((button) => {
    button.addEventListener("click", () => {
      navigator.clipboard.writeText(currentUrl);
      button.disabled = true;
      const tooltip = document.createElement("span");
      const shareSection = document.querySelector(".share__icons");
      tooltip.classList.add("copy__item-tooltip");
      const tooltipText = document.createTextNode("Adresse wurde kopiert");
      tooltip.appendChild(tooltipText);
      shareSection.appendChild(tooltip);

      // Delete the tooltip from Dom to avoid duplicates and activate the button again
      function animationEndFunction () {
        tooltip.removeEventListener('animationend', animationEndFunction)
        button.disabled = false;
        tooltip.remove()
      }

      tooltip.addEventListener('animationend', animationEndFunction);
    });
  });
}
