/**
 * checks if all the required inputs are filled to enable the submitButton
 * @return {void}
 */

export function initSubmitButton() {
  const submitButton = document.querySelector(".button--contactSubmit");
  const inputs = document.querySelectorAll("form input[required]");

  // TODO: Check that all of them are filled to enable send button
  // const filledInputs = [...inputs].filter((input) => input.value !== "");
  // if (submitButton) {
  //   submitButton.disabled = true;
  //   if (inputs === filledInputs) {
  //     submitButton.disabled = false;
  //   }
  // }
}
