export const initAudioPlayer = () => {
  document.querySelectorAll('.audio-player-container')
    .forEach((playerWrapper) => {
      const audioPlayer = playerWrapper.querySelector(".audio-player");
      if (audioPlayer) {
        audioPlayer.tabIndex = "-1";
      }

      if (audioPlayer.readyState === 4) {
        initSingleAudioPlayer(playerWrapper, audioPlayer);
      } else {
        audioPlayer.addEventListener('canplay', () => initSingleAudioPlayer(playerWrapper, audioPlayer));
      }
    });
};

const initSingleAudioPlayer = (playerWrapper, audioPlayer) => {
  const audioDurationElement = playerWrapper.querySelector(".audio__duration-left");

    if (!audioPlayer || !audioDurationElement) {
      return;
    }
  initSingleAudioPlayerByWrapper(playerWrapper, audioPlayer, audioDurationElement);
};

const initSingleAudioPlayerByWrapper = (playerWrapper, audioPlayer, audioDurationElement) => {
  let videoTrack = "";
  let stopSpam = "";

  const playAudioButton = playerWrapper.querySelector(".btn-play-audio");

  // Variables / Const regarding the track
  const sliderWrapper = playerWrapper.querySelector(".audio-slider__wrapper");
  const trackInput = sliderWrapper.querySelector(".audio-player-input");
  const trackFill = sliderWrapper.querySelector(".audio-fill");
  const trackThumb = sliderWrapper.querySelector(".audio-thumb");

  const VolumeWrapper = playerWrapper.querySelector(".audio-volume-slider__wrapper");
  const volumeInput = VolumeWrapper.querySelector(".audio-player-input");
  const volumeFill = VolumeWrapper.querySelector(".audio-fill");
  const volumeThumb = VolumeWrapper.querySelector(".audio-thumb");

  const audioCurrentElement = playerWrapper.querySelector(".audio__current-time");

  trackFill.style.width = '0%';
  volumeFill.style.width = '100%';

  let audioCurrent = audioPlayer.currentTime;
  let audioTotalDuration = Math.floor(audioPlayer.duration);

  audioDurationElement.innerHTML = getTimeProperly(audioTotalDuration);

  // Handle behaviour of play / pause button
  playAudioButton.addEventListener("click", () => {
    if (playAudioButton.classList.contains("pause")) {
      pause(playAudioButton, audioPlayer, videoTrack);
    } else {
      initSong(videoTrack, audioCurrent, audioTotalDuration, trackThumb, trackFill, audioCurrentElement, audioPlayer, playAudioButton);
      play(playAudioButton, audioPlayer);
    }
  });
  // Handle behaviour of the input range / track
  trackInput.setAttribute("max", audioTotalDuration);

  trackInput.addEventListener("input", event => onSliderChange(event, playAudioButton, audioPlayer, videoTrack, audioCurrentElement, audioCurrent, stopSpam, trackThumb, audioTotalDuration, trackFill));
  trackInput.addEventListener("change", event => onSliderChange(event, playAudioButton, audioPlayer, videoTrack, audioCurrentElement, audioCurrent, stopSpam, trackThumb, audioTotalDuration, trackFill));
  setCurrentTime(audioCurrentElement, getTimeProperly(audioCurrent));

  volumeInput.addEventListener("input", event => onVolumeChange(event, audioPlayer, volumeThumb, volumeFill));
  volumeInput.addEventListener("change", event => onVolumeChange(event, audioPlayer, volumeThumb, volumeFill));
};



const getTimeProperly = (time) => {
  return Math.floor(time / 60) + ":" + ("0" + Math.floor(time % 60)).slice(-2);
};

function pause(playAudioButton, audioPlayer, videoTrack) {
  audioPlayer.pause();
  if (videoTrack) clearInterval(videoTrack);
  playAudioButton.classList.remove("pause");
  playAudioButton.setAttribute("aria-label", "Audio abspielen");
  playAudioButton.setAttribute("title", "Audio abspielen");
}

function play(playAudioButton, audioPlayer) {
  audioPlayer.play();
  playAudioButton.classList.add("pause");
  playAudioButton.setAttribute("aria-label", "Audio pausieren");
  playAudioButton.setAttribute("title", "Audio pausieren");
}
const initSong = (videoTrack, audioCurrent, audioTotalDuration, trackThumb, trackFill, audioCurrentElement, audioPlayer, playAudioButton) => {
  audioPlayer.addEventListener('timeupdate', () => {
    audioCurrent = audioPlayer.currentTime;
    trackThumb.style.left = (audioCurrent / audioTotalDuration) * 100 + "%";
    trackFill.style.width = (audioCurrent / audioTotalDuration) * 100 + "%";
    // Handle behaviour of the audioCurrent
    setCurrentTime(audioCurrentElement, getTimeProperly(audioCurrent));
    if (audioCurrent >= audioTotalDuration) {
      clearInterval(videoTrack);
      audioPlayer.pause();
      trackThumb.style.left =
        (audioCurrent / audioTotalDuration) * 100 + "%";
      trackFill.style.width =
        (audioCurrent / audioTotalDuration) * 100 + "%";
      setCurrentTime(audioCurrentElement, getTimeProperly(audioCurrent));
      playAudioButton.classList.remove("pause");
      playAudioButton.setAttribute("aria-label", "Audio abspielen");
      playAudioButton.setAttribute("title", "Audio abspielen");
      return;
    }
  });
};

function onSliderChange(event, playAudioButton, audioPlayer, videoTrack, audioCurrentElement, audioCurrent, stopSpam, trackThumb, audioTotalDuration, trackFill) {
  pause(playAudioButton, audioPlayer, videoTrack);
  setCurrentTime(audioCurrentElement, getTimeProperly(audioCurrent));
  if (stopSpam) clearTimeout(stopSpam);
  let value = event.target.value;
  audioPlayer.currentTime = value;
  audioCurrent = audioPlayer.currentTime;
  trackThumb.style.left = (value / audioTotalDuration) * 100 + "%";
  trackFill.style.width = (value / audioTotalDuration) * 100 + "%";
  stopSpam = setTimeout(() => {
    initSong(videoTrack, audioCurrent, audioTotalDuration, trackThumb, trackFill, audioCurrentElement, audioPlayer, playAudioButton);
    play(playAudioButton, audioPlayer);
  }, 400);
}

function onVolumeChange(event, audioPlayer, volumeThumb, volumeFill) {
  if (!event.target.value) return;

  let volume = event.target.value;
  audioPlayer.volume = volume;
  volumeThumb.style.left = (volume / 1) * 100 + "%";
  volumeFill.style.width = (volume / 1) * 100 + "%";
}

function setCurrentTime(element, html) {
  element.innerHTML = html;
}
